import { entityAdapter } from "../slice/slice";
import { RootState } from "../../../app/store";
import { ENTITY_NAME } from "../config";
import { createSelector } from "@reduxjs/toolkit";
import { selectEntities as unit_selectEntities } from "../.././unit/selectors/selectors";
import { selectEntities as scenario_selectEntities } from "../.././scenario/selectors/selectors";
import { selectProfile } from "../../auth/selectors/auth.selector";

const selectors = entityAdapter.getSelectors(
  (state: RootState) => state[ENTITY_NAME].entities
);

export const selectSelected = (state: any) => {
  return state[ENTITY_NAME].selected;
};

export const selectSelectedMode = (state: any) => {
  return state[ENTITY_NAME].selectedMode;
};

export const selectAll = (state: any) => {
  return selectors.selectAll(state);
};

export const selectAllFlatten = createSelector(
  [selectAll, unit_selectEntities, scenario_selectEntities],
  (reports, units, scenarios) => {
    return reports.map((report) => {
      return {
        ...report,
        unit: units[report.unitId as unknown as string],
        scenario: scenarios[report.scenarioId as unknown as string],
      };
    });
  }
);

export const selectAllFlattenByProfile = createSelector(
  [selectAllFlatten, selectProfile],
  (reports, profile) => {
    if (!profile) {
      return [];
    }

    const unitIdsByProfile = profile.units.map((u) => u.id);
    return reports.filter((r) => unitIdsByProfile.includes(r?.unit?.id || "0"));
  }
);
