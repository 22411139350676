import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import React, { useEffect, useState } from "react";
import { selectSelected, selectSelectedMode } from "../../selectors/selectors";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  confirmCreate,
  confirmUpdate,
  resetSelected,
  resetSelectedMode,
  setSelected,
} from "../../actions/actions";
import { ENTITY_TYPE } from "../../config";
import { unwrapResult } from "@reduxjs/toolkit";
import CronUi from "../../../common/components/CronUi/CronUi";
import { selectAll as unitSelectAll } from "../../.././unit/selectors/selectors";
import { selectAllSorted as scenarioSelectAll } from "../../.././scenario/selectors/selectors";
import MuiAutocomplete from "../../../common/components/MuiAutocomplete/MuiAutocomplete";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import CloseButton from "../../../common/components/CloseButton/CloseButton";
import { getHDMLabel } from "../../utils/utils";

const DialogDetail = () => {
  const dispatch = useAppDispatch();
  const mode = useAppSelector(selectSelectedMode);
  const entity = useAppSelector(selectSelected);
  const units = useAppSelector(unitSelectAll);
  const scenarios = useAppSelector(scenarioSelectAll);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = (entity: ENTITY_TYPE) => {
    switch (mode) {
      case "create":
        {
          dispatch(confirmCreate(entity))
            .then(unwrapResult)
            .then((result: any) => {
              handleClose();
            });
        }
        break;
      case "update":
        {
          dispatch(confirmUpdate(entity))
            .then(unwrapResult)
            .then((result: any) => {
              handleClose();
            });
        }
        break;
    }
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    dispatch(setSelected({ ...entity, [name]: value }));
  };

  const handleChangeCheckbox = (event: any) => {
    dispatch(
      setSelected({ ...entity, [event.target.name]: event.target.checked })
    );
  };

  useEffect(() => {
    if (mode === "create" || mode === "update") {
      setOpen(true);
    }
  }, [mode]);

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        dispatch(resetSelectedMode());
        dispatch(resetSelected());
      }, 500);
    }
  }, [open]);

  if (!entity) {
    return <></>;
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        {mode === "create" ? "Nový předpis" : "Úprava předpisu"}
        <CloseButton onClick={handleClose} />
      </DialogTitle>
      <Divider />
      <DialogContent>
        <FormControlLabel
          control={
            <Checkbox
              name="enabled"
              checked={entity.enabled}
              onChange={handleChangeCheckbox}
            />
          }
          label="Povolený"
        />
        <MuiAutocomplete
          label={"Střediska"}
          options={units}
          getOptionLabel={(option) => option.storeNumber}
          value={entity.unitIds}
          onChange={(newValue) => {
            handleChange({
              target: {
                name: "unitIds",
                value: newValue.map((n) => n.id),
              },
            });
          }}
        />

        <MuiAutocomplete
          label={"Scénáře"}
          options={scenarios}
          getOptionLabel={(option) => option.code + ": " + option.title}
          value={entity.scenarioIds}
          onChange={(newValue) => {
            handleChange({
              target: {
                name: "scenarioIds",
                value: newValue.map((n) => n.id),
              },
            });
          }}
        />

        <FormControlLabel
          sx={{ mt: 2 }}
          control={
            <Checkbox
              name="withCron"
              checked={entity.withCron}
              onChange={handleChangeCheckbox}
            />
          }
          label="Scénáře mají být vyplněny v konkrétní časy"
        />

        <Collapse in={entity.withCron}>
          <CronUi
            onChange={(expression) => {
              handleChange({
                target: {
                  name: "cronExpression",
                  value: expression,
                },
              });
            }}
            defaultExpression={entity.cronExpression}
          />

          <Box sx={{ display: "flex" }}>
            <Box sx={{ mr: 2 }}>
              <TextField
                sx={{ mt: 2 }}
                type={"number"}
                label={"Čas na vyplnění"}
                onChange={(event) => {
                  handleChange({
                    target: {
                      name: "ttl",
                      value: event.target.value,
                    },
                  });
                }}
                value={entity.ttl}
              />
            </Box>
            <Box>
              <Select
                sx={{ mt: 2 }}
                fullWidth
                label={"Jednotka"}
                onChange={(event) => {
                  handleChange({
                    target: {
                      name: "ttlUnit",
                      value: event.target.value,
                    },
                  });
                }}
                value={entity.ttlUnit}
              >
                {["H", "D", "M"].map((u) => {
                  return (
                    <MenuItem key={u} value={u}>
                      {getHDMLabel(u as "H" | "D" | "M", entity.ttl)}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Box>
        </Collapse>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant={"text"}
          onClick={() => {
            handleClose();
          }}
          startIcon={<CloseIcon />}
        >
          Zrušit
        </Button>
        <Button
          variant={"contained"}
          onClick={() => {
            handleConfirm(entity);
          }}
          startIcon={<SaveIcon />}
        >
          Uložit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDetail;
