import React from "react";
import { Delete, Edit } from "@mui/icons-material";
import { selectAllByProfile } from "../selectors/selectors";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ENTITY_TYPE } from "../config";
import { remove, update } from "../actions/actions";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import CrudList from "../../common/components/CrudList/CrudList";
import { Tooltip } from "@mui/material";
import CryptoJS from "crypto-js";

const List = () => {
  const dispatch = useAppDispatch();
  const items = useAppSelector(selectAllByProfile);

  const columns: GridColDef[] = [
    {
      field: "storeNumber",
      headerName: "Číslo střediska",
      flex: 2,
    },
    {
      field: "brandCode",
      headerName: "Koncept",
      flex: 2,
    },
    {
      field: "email",
      headerName: "E-mail",
      flex: 2,
    },
    {
      field: "emailFieldManager",
      headerName: "E-mail FM",
      flex: 2,
    },
    {
      field: "credentials",
      headerName: "Přihlašovací údaje",
      flex: 2,
      valueGetter: (params: GridValueGetterParams) => {
        return (
          params.row.storeNumber +
          " / " +
          CryptoJS.MD5(params.row.storeNumber)
            .toString(CryptoJS.enc.Hex)
            .substring(0, 6)
        );
      },
    },
    {
      field: "actions",
      headerName: "Akce",
      flex: 1,
      type: "actions",
      sortable: false,
      filterable: false,
      hideable: false,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={
            <Tooltip title="Upravit">
              <Edit />
            </Tooltip>
          }
          onClick={() => {
            handleUpdate(params.row);
          }}
          label={"Upravit"}
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title="Odstranit">
              <Delete />
            </Tooltip>
          }
          onClick={() => {
            handleDelete(params.row);
          }}
          label={"Odstranit"}
        />,
      ],
    },
  ];

  const handleUpdate = (entity: ENTITY_TYPE) => {
    dispatch(update(entity));
  };

  const handleDelete = (entity: ENTITY_TYPE) => {
    dispatch(remove(entity));
  };

  return <CrudList columns={columns} items={items} />;
};

export default List;
