import React from "react";
import { Delete, Edit } from "@mui/icons-material";
import { selectAllSorted } from "../selectors/selectors";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ENTITY_TYPE } from "../config";
import { remove, update } from "../actions/actions";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid";
import CrudList from "../../common/components/CrudList/CrudList";
import { Tooltip } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { scenarioCodeComparatorFn } from "../../../utils/scenarioCodeComparatorFn.util";

const List = () => {
  const dispatch = useAppDispatch();
  const items = useAppSelector(selectAllSorted);

  const columns: GridColDef[] = [
    {
      field: "enabled",
      headerName: "Povolený",
      flex: 1,
      renderCell: (params) => {
        if (!!params.value) {
          return <CheckBoxIcon />;
        }
        return <CheckBoxOutlineBlankIcon />;
      },
    },
    {
      field: "code",
      headerName: "Kód",
      flex: 1,
      sortComparator: scenarioCodeComparatorFn,
    },
    {
      field: "title",
      headerName: "Název",
      flex: 5,
    },
    {
      field: "createdBy",
      headerName: "Vytvořil",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Akce",
      flex: 1,
      type: "actions",
      sortable: false,
      filterable: false,
      hideable: false,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={
            <Tooltip title="Upravit">
              <Edit />
            </Tooltip>
          }
          onClick={() => {
            handleUpdate(params.row);
          }}
          label={"Upravit"}
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title="Odstranit">
              <Delete />
            </Tooltip>
          }
          onClick={() => {
            handleDelete(params.row);
          }}
          label={"Odstranit"}
        />,
      ],
    },
  ];

  const handleUpdate = (entity: ENTITY_TYPE) => {
    dispatch(update(entity));
  };

  const handleDelete = (entity: ENTITY_TYPE) => {
    dispatch(remove(entity));
  };

  return <CrudList columns={columns} items={items} />;
};

export default List;
