import { TextField } from "@mui/material";
import React from "react";

const ReportBuilderFormFieldTitle = (props: any) => {
  const { header, handleChange } = props;

  return (
    <TextField
      sx={{ mr: 1 }}
      label="Název sloupce v reportu"
      value={header.title}
      onChange={(e) => handleChange(header.id, "title", e.target.value)}
      style={{ marginRight: "10px" }}
    />
  );
};

export default ReportBuilderFormFieldTitle;
