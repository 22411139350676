import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../services/axios";
import { BASE_URL, ENTITY_NAME } from "../config";
import { buildHeaders } from "../../../api/api";
import { store } from "../../../app/store";

const buildUrlWithBrandsFilter = (url: string) => {
  // @ts-ignore
  const brands: string[] = store.getState().auth?.profile?.brands || [];
  return url + "?" + brands.map((b) => "brands[]=" + b).join("&");
};

export const thunks = {
  getAll: createAsyncThunk(
    ENTITY_NAME + "/getAll",
    async (arg: any, { rejectWithValue }) => {
      try {
        const response = await axios.get(buildUrlWithBrandsFilter(BASE_URL), {
          headers: buildHeaders({
            "content-type": "application/ld+json",
          }),
        });
        if (response.status === 200) {
          return response.data["hydra:member"].map((r: any) => {
            return r;
          });
        } else {
          new Error();
        }
      } catch (error: any) {
        console.log(error);
        return rejectWithValue(error.message);
      }
    }
  ),
  post: createAsyncThunk(
    ENTITY_NAME + "/post",
    async (arg: any, { rejectWithValue }) => {
      try {
        const { data } = arg;
        const response = await axios.post(
          BASE_URL,
          { ...data, enabled: true },
          {
            headers: buildHeaders({
              "content-type": "application/ld+json",
            }),
          }
        );
        if (response.status === 201) {
          return response.data;
        } else {
          new Error();
        }
      } catch (error: any) {
        return rejectWithValue(error.message);
      }
    }
  ),
  put: createAsyncThunk(
    ENTITY_NAME + "/put",
    async (arg: any, { rejectWithValue }) => {
      try {
        const { data } = arg;
        const response = await axios.put(BASE_URL + "/" + data.id, data, {
          headers: buildHeaders({
            "content-type": "application/ld+json",
          }),
        });
        if (response.status === 200) {
          return response.data;
        } else {
          new Error();
        }
      } catch (error: any) {
        return rejectWithValue(error.message);
      }
    }
  ),
  delete: createAsyncThunk(
    ENTITY_NAME + "delete",
    async (arg: any, { rejectWithValue }) => {
      try {
        const { id } = arg.data;
        const response = await axios.delete(BASE_URL + "/" + id, {
          headers: buildHeaders({
            "content-type": "application/ld+json",
          }),
        });
        if (response.status === 204) {
          return id;
        } else {
          new Error();
        }
      } catch (error: any) {
        return rejectWithValue(error.message);
      }
    }
  ),
};
