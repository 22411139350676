import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { confirmCreate, confirmUpdate } from "../../actions/actions";
import { ENTITY_TYPE } from "../../config";
import ScenarioEditorDialog from "../../../././scenarioEditor/components/ScenarioEditorDialog";
import React from "react";
import { selectSelectedMode } from "../../selectors/selectors";

const DialogDetail = () => {
  const dispatch = useAppDispatch();
  const mode = useAppSelector(selectSelectedMode);

  const handleConfirm = (entity: ENTITY_TYPE) => {
    switch (mode) {
      case "create":
        {
          dispatch(confirmCreate(entity));
        }
        break;
      case "update":
        {
          dispatch(confirmUpdate(entity));
        }
        break;
    }
  };

  return <ScenarioEditorDialog onSubmit={handleConfirm} />;
};

export default DialogDetail;
