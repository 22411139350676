import { v4 as uuidv4 } from "uuid";
import { EdgeType } from "../../../types/EdgeType.type";

export const edgeConditionOperationTypes = {
  noOperation: {
    label: "žádná operace",
    description: "",
    fnOrder: 900,
    fn: (executionValue: any, value: any) => true,
  },
  greaterOrEqual: {
    label: "větší nebo rovno",
    description: "",
    fnOrder: 60,
    fn: (executionValue: any, value: any) => executionValue * 1 >= value * 1,
  },
  greater: {
    label: "větší než",
    description: "",
    fnOrder: 50,
    fn: (executionValue: any, value: any) => executionValue * 1 > value * 1,
  },
  lessOrEqual: {
    label: "menší nebo rovno",
    description: "",
    fnOrder: 40,
    fn: (executionValue: any, value: any) => executionValue * 1 <= value * 1,
  },
  less: {
    label: "menší než",
    description: "",
    fnOrder: 30,
    fn: (executionValue: any, value: any) => executionValue * 1 < value * 1,
  },
  equal: {
    label: "rovno",
    description: "",
    fnOrder: 20,
    fn: (executionValue: any, value: any) => executionValue === value,
  },
  between: {
    label: "mezi (včetně)",
    description: "zadejte 2 číselné hodnoty oddělené středníkem",
    fnOrder: 10,
    fn: (executionValue: any, value: any) => {
      const v = value.split(";").map((x: any) => x.trim() * 1);
      return executionValue >= v[0] && executionValue <= v[1];
    },
  },
};

export const buildEdge = (
  sourceId: string,
  destinationId: string
): EdgeType => {
  return {
    id: uuidv4(),
    sourceId: sourceId,
    destinationId: destinationId,
    values: {
      condition: {
        operation: "noOperation",
        value: "",
      },
    },
  };
};
