export const getHDMLabel = (hdm: "H" | "D" | "M", value: number) => {
  switch (hdm) {
    case "H": {
      return value === 1
        ? "hodina"
        : value > 1 && value < 5
        ? "hodiny"
        : "hodin";
    }
    case "D": {
      return value === 1 ? "den" : value > 1 && value < 5 ? "dny" : "dnů";
    }
    case "M": {
      return value === 1
        ? "měsíc"
        : value > 1 && value < 5
        ? "měsíce"
        : "měsíců";
    }
  }
};
