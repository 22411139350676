import React from "react";
import { cs as localeCs } from "date-fns/locale";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ThemeProvider } from "@mui/material";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { store } from "./app/store";
import theme from "./themes/theme";
import { RouterProvider } from "react-router-dom";
import router from "./app/router";
import LoaderProvider from "./providers/LoaderProvider";

const App = () => {
  return (
    <>
      <Helmet>
        <title>LTR Kontrolní listy</title>
        <meta name="robots" content="noindex,nofollow"></meta>
      </Helmet>
      <Provider store={store}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={localeCs}
        >
          <ThemeProvider theme={theme}>
            <LoaderProvider />
            <RouterProvider router={router} />
          </ThemeProvider>
        </LocalizationProvider>
      </Provider>
    </>
  );
};

export default App;
