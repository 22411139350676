import {
  createEntityAdapter,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";
import { thunks } from "../thunks/thunks";
import { ENTITY_NAME, ENTITY_TYPE } from "../config";

export const entityAdapter = createEntityAdapter<ENTITY_TYPE>();

export type StateType = {
  selected: null | ENTITY_TYPE;
  selectedMode: null | "create" | "update" | "delete";
  entities: EntityState<ENTITY_TYPE>;
};

const initialState: StateType = {
  selected: null,
  selectedMode: null,
  entities: entityAdapter.getInitialState(),
};

export const slice = createSlice({
  name: ENTITY_NAME,
  initialState: initialState,
  reducers: {
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
    setSelectedMode: (state, action) => {
      state.selectedMode = action.payload;
    },
  },
  extraReducers: (builder) => {
    Object.entries(thunks).forEach(([key, value]) => {
      switch (key) {
        case "getAll":
          {
            builder.addCase(value.fulfilled, (state, action) => {
              entityAdapter.setAll(state.entities, action.payload);
            });
          }
          break;
        case "post":
          {
            builder.addCase(value.fulfilled, (state, action) => {
              entityAdapter.upsertOne(state.entities, action.payload);
            });
          }
          break;
        case "put":
          {
            builder.addCase(value.fulfilled, (state, action) => {
              entityAdapter.upsertOne(state.entities, action.payload);
            });
          }
          break;
        case "delete":
          {
            builder.addCase(value.fulfilled, (state, action) => {
              entityAdapter.removeOne(state.entities, action.payload);
            });
          }
          break;
      }
    });
  },
});
