// @ts-nocheck

import React from "react";
import { Box, Button, Divider, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { buildReportHeader } from "../../builders/reportHeader.builder";
import ReportBuilderFormFieldPosition from "./ReportBuilderFormFieldPosition";
import ReportBuilderFormFieldTitle from "./ReportBuilderFormFieldTitle";
import ReportBuilderFormFieldValue from "./ReportBuilderFormFieldValue";
import { useAppDispatch } from "../../../../app/hooks";
import { scenarioEditorSlice } from "../../scenarioEditorSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import AddCircleIcon from '@mui/icons-material/AddCircle';

// Definice typu pro objekt v headers
interface HeaderItem {
  id: number;
  position: number;
  title: string;
  customIds: [];
}

const ReportBuilder: React.FC = () => {
  const dispatch = useAppDispatch();
  // Stav s počáteční hodnotou prázdného pole objektů v headers
  const headers = useSelector(
    (state: RootState) => state.scenarioEditor.scenario?.reportHeaders || []
  );

  const handleAddHeader = () => {
    const newHeader = buildReportHeader() as unknown as HeaderItem;
    newHeader.position = headers.length + 1;

    updateHeaders([...headers, newHeader]);
  };

  // Funkce pro aktualizaci názvu nebo hodnoty objektu ve stavu headers
  const handleChange = (
    id: number,
    field: keyof HeaderItem,
    value: string | string[]
  ) => {
    const index = headers.findIndex((h: any) => h.id === id);
    if (index !== -1) {
      const copy = structuredClone(headers);
      copy[index][field] = value;
      updateHeaders(copy);
    }
  };

  // Funkce pro odstranění objektu ze stavu headers
  const handleRemoveHeader = (id: number) => {
    // Filtrujeme stav headers a odstraníme objekt s daným id
    const updatedHeaders = headers.filter((header: any) => header.id !== id);

    // Aktualizujeme stav headers
    updateHeaders(updatedHeaders);
  };

  const updateHeaders = (newValue: any) => {
    dispatch(
      scenarioEditorSlice.actions.scenarioUpdate({
        name: "reportHeaders",
        value: newValue,
      })
    );
  };

  return (
    <Box>
      {/* Vykreslení všech objektů v headers */}
      {headers.map((header: any) => (
        <Box
          key={header.id}
          sx={{ width: "100%", mb: 2, display: "flex", alignItems: "center" }}
        >
          <ReportBuilderFormFieldPosition
            header={header}
            handleChange={handleChange}
          />
          <ReportBuilderFormFieldTitle
            header={header}
            handleChange={handleChange}
          />
          <ReportBuilderFormFieldValue
            header={header}
            handleChange={handleChange}
          />

          {/* Tlačítko pro odstranění objektu */}
          <IconButton
            size={"small"}
            color="secondary"
            onClick={() => handleRemoveHeader(header.id)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}
      {/* Tlačítko pro přidání nového objektu */}
      <Button variant="contained" color="secondary" onClick={handleAddHeader} startIcon={<AddCircleIcon />}>
        Přidat sloupec
      </Button>
    </Box>
  );
};

export default ReportBuilder;
