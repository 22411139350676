import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Node from "./Node";
import Edge from "./Edge";
import { RootState } from "../../../app/store";
import { scenarioEditorSlice } from "../scenarioEditorSlice";

const Canvas = () => {
  const dispatch = useDispatch();

  const nodes = useSelector(
    (state: RootState) => state.scenarioEditor.scenario?.nodes || {}
  );

  const nodeCoordinates = useSelector(
    (state: RootState) => state.scenarioEditor.scenario?.nodeCoordinates || {}
  );
  const edges = useSelector(
    (state: RootState) => state.scenarioEditor.scenario?.edges || {}
  );
  const edgeCoordinates = useSelector(
    (state: RootState) => state.scenarioEditor.scenario?.edgeCoordinates || {}
  );

  React.useEffect(() => {
    dispatch(scenarioEditorSlice.actions.redrawEdges());
  }, [edges, nodeCoordinates]);

  return (
    <>
      {Object.keys(nodes).map((key) => {
        const node = nodes[key];
        const coordinates = nodeCoordinates[key];
        return <Node key={key} coordinates={coordinates} node={node} />;
      })}
      {Object.keys(edges).map((key) => {
        const edge = edges[key];
        const coordinates = edgeCoordinates[key];
        if (!edge || !coordinates) {
          return <React.Fragment key={key} />;
        }
        return <Edge key={key} coordinates={coordinates} edge={edge} />;
      })}
    </>
  );
};

export default Canvas;
