import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Theme,
  useTheme,
} from "@mui/material";
import React from "react";
import { useAppSelector } from "../../../../app/hooks";
import { selectSelectedScenarioNodesForReportBuilder } from "../../.././scenario/selectors/selectors";
import { NodeType } from "../../../../types/NodeType.type";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(nodeId: string, nodeIds: readonly string[], theme: Theme) {
  return {
    fontWeight:
      nodeIds.indexOf(nodeId) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ReportBuilderFormFieldValue = (props: any) => {
  const { header, handleChange } = props;

  const theme = useTheme();
  const nodes = useAppSelector((state) =>
    selectSelectedScenarioNodesForReportBuilder(state)
  );

  const getValueOfNode = (node: NodeType) => {
    return node?.values?.customId || node?.id || "";
  };
  const getLabelOfNode = (node: NodeType) => {
    return (
      node?.values?.customId || node?.values?.displayText || node?.id || ""
    );
  };
  const getLabelOfValue = (value: string) => {
    // @ts-ignore
    const index = nodes.findIndex(
      (n: any) => n.id === value || n.values.customId === value
    );
    if (index > -1) {
      // @ts-ignore
      return getLabelOfNode(nodes[index] as NodeType);
    }
    return "";
  };

  return (
    <FormControl sx={{ mr: 1, flexGrow: 1 }}>
      <InputLabel>Hodnota sloupce v reportu</InputLabel>
      <Select
        label={"Hodnota sloupce v reportu"}
        multiple
        value={header.value}
        onChange={(e) => {
          const {
            target: { value },
          } = e;
          handleChange(
            header.id,
            "value",
            typeof value === "string" ? value.split(",") : value
          );
        }}
        input={<OutlinedInput label="Hodnota sloupce v reportu" />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {selected.map((value: any) => (
              <Chip
                size={"small"}
                key={value}
                label={getLabelOfValue(value)}
                sx={{ mr: 0.5 }}
              />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {nodes.map((node: any) => (
          <MenuItem
            key={node.id}
            value={getValueOfNode(node)}
            style={getStyles(node.id, header.value, theme)}
          >
            {getLabelOfNode(node)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ReportBuilderFormFieldValue;
