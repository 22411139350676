export const formatDatetime = (dtString: string) => {
  const dt = new Date(dtString);

  const y = dt.getFullYear();
  const m = dt.getMonth() + 1;
  const d = dt.getDate();
  const h = dt.getHours();
  const i = dt.getMinutes();

  return (
    [d < 10 ? "0" + d : d, m < 10 ? "0" + m : m, y].join(".") +
    " " +
    [h < 10 ? "0" + h : h, i < 10 ? "0" + i : i].join(":")
  );
};
