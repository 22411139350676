import { v4 as uuidv4 } from "uuid";

export const buildReportHeader = () => {
  return {
    id: uuidv4(),
    position: 0,
    title: "",
    value: [],
  };
};
