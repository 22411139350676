import { useEffect } from "react";
import { useAppDispatch } from "../app/hooks";
import { load as scenarioLoad } from "../features/./scenario/actions/actions";
import { load as scenarioPrescriptionLoad } from "../features/./scenarioPrescription/actions/actions";
import { load as unitLoad } from "../features/./unit/actions/actions";
import { load as scenarioExecutionReportLoad } from "../features/./scenarioExecutionReport/actions/actions";
import { useSelector } from "react-redux";
import { selectProfile } from "../features/auth/selectors/auth.selector";
//import { test } from "../utils/scenarioCodeComparatorFn.util.test";

const LoaderProvider = () => {
  const dispatch = useAppDispatch();
  const profile = useSelector(selectProfile);

  useEffect(() => {
    //test();
    if (!!profile) {
      dispatch(scenarioLoad());
      dispatch(scenarioPrescriptionLoad());
      dispatch(unitLoad());
      dispatch(scenarioExecutionReportLoad());
    }
  }, [profile]);

  return <></>;
};

export default LoaderProvider;
