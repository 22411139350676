import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectSelected, selectSelectedMode } from "../../selectors/selectors";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import {
  confirmDelete,
  resetSelected,
  resetSelectedMode,
} from "../../actions/actions";
import { unwrapResult } from "@reduxjs/toolkit";

const DialogDelete = () => {
  const dispatch = useAppDispatch();
  const mode = useAppSelector(selectSelectedMode);
  const entity = useAppSelector(selectSelected);

  const open = mode === "delete";

  const handleClose = () => {
    dispatch(resetSelectedMode());
    setTimeout(() => {
      dispatch(resetSelected());
    }, 500);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Potvrzení odstranění</DialogTitle>
      <DialogContent>
        <Typography>Opravdu chcete odstranit předpis scénáře?</Typography>
        <Typography>Odstranění je nevratné.</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant={"text"}
          onClick={() => {
            handleClose();
          }}
        >
          Ne
        </Button>
        <Button
          variant={"contained"}
          onClick={() => {
            dispatch(confirmDelete(entity))
              .then(unwrapResult)
              .then((result: any) => {
                handleClose();
              });
          }}
        >
          Ano, odstranit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDelete;
