export const NODE_TYPE__START = "start";
export const NODE_TYPE__TEXT = "text";
export const NODE_TYPE__INPUT_TEXT = "inputText";
export const NODE_TYPE__INPUT_NUMERIC = "inputNumeric";
export const NODE_TYPE__INPUT_FROM_LIST = "inputFromList";
export const NODE_TYPE__INPUT_DATE = "inputDate";
export const NODE_TYPE__INPUT_DATE_TIME = "inputDateTime";
export const NODE_TYPE__INPUT_UPLOAD_IMAGE = "inputUploadImage";
export const NODE_TYPE__NOTIFICATION = "notification";
export const NODE_TYPE__LOOP_INCREMENT_ONE = "loopIncrementOne";
export const NODE_TYPE__END = "end";

export const nodeTypesInputs = [
  NODE_TYPE__INPUT_TEXT,
  NODE_TYPE__INPUT_NUMERIC,
  NODE_TYPE__INPUT_FROM_LIST,
  NODE_TYPE__INPUT_DATE,
  NODE_TYPE__INPUT_DATE_TIME,
  NODE_TYPE__INPUT_UPLOAD_IMAGE,
];
