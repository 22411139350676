import { grey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#ffffff",
      main: "#3f51b5",
    },
    secondary: {
      main: "#e50178",
    },
    text: {
      primary: "#12161B",
    },
    background: {
      default: "#f8fafc",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: grey[200],
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:hover td": {
            backgroundColor: "#e1f5fe !important",
            opacity: "1",
            // cursor: "ew-resize",
          },
        },
      },
    },
  },
});

export default theme;
