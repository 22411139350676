import React from "react";
import List from "./List";
import { useAppDispatch } from "../../../app/hooks";
import { load } from "../actions/actions";

const Crud = () => {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(load());
  }, []);

  return (
    <>
      <List />
    </>
  );
};

export default Crud;
