import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import ScenarioEditor from "./ScenarioEditor";
import { scenarioEditorSlice } from "../scenarioEditorSlice";
import { Save } from "@mui/icons-material";
import { useEffect, useState } from "react";
import ReportBuilder from "./ReportBuilder/ReportBuilder";
import CloseButton from "../../common/components/CloseButton/CloseButton";
import ScenarioExecutionDebugDialog from "../../scenarioExecutionDebug/components/ScenarioExecutionDebugDialog";

const ScenarioEditorDialog = (props: any) => {
  const { onSubmit } = props;

  const dispatch = useDispatch();

  const scenario = useSelector(
    (state: RootState) => state.scenarioEditor.scenario
  );

  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState<string>("editor");

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      dispatch(scenarioEditorSlice.actions.setScenario(null));
    }, 500);
  };

  const handleChange = (event: any) => {
    const payload = {
      name: event.target.name,
      value: event.target.value,
    };

    dispatch(scenarioEditorSlice.actions.updateScenario(payload));
  };

  const handleChangeCheckbox = (event: any) => {
    const payload = {
      name: event.target.name,
      value: event.target.checked,
    };

    dispatch(scenarioEditorSlice.actions.updateScenario(payload));
  };

  const handleSubmit = () => {
    if (scenario) {
      onSubmit(scenario);
      handleClose();
    }
  };

  useEffect(() => {
    if (scenario) {
      setOpen(true);
    }
  }, [scenario]);

  if (!scenario) {
    return <></>;
  }

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"xl"}
      onClose={handleClose}
      sx={{
        "& > .MuiDialog-container": {
          alignItems: "flex-start",
        },
      }}
      PaperProps={{ sx: { height: "100%" } }}
    >
      <DialogTitle>
        Editor scénáře
        <CloseButton onClick={handleClose} />
      </DialogTitle>
      <Divider />
      <Tabs
        value={tab}
        onChange={(event, newValue) => {
          setTab(newValue);
        }}
      >
        <Tab label="Editor" value={"editor"} />
        <Tab label="Sestava reportu" value={"reportBuilder"} />
      </Tabs>
      <Divider />
      <DialogContent sx={{ p: 0 }}>
        <Box
          sx={{
            display: tab === "editor" ? "flex" : "none",
            flexDirection: "column",
            flexGrow: 1,
            height: "100%",
          }}
        >
          <Box sx={{ p: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  name="enabled"
                  checked={scenario.enabled}
                  onChange={handleChangeCheckbox}
                />
              }
              label="Povolený"
            />
            <TextField
              name={"code"}
              sx={{ width: 100 }}
              label={"Kód scénáře"}
              value={scenario.code}
              onChange={handleChange}
              size={"small"}
            />
            <TextField
              name={"title"}
              label={"Název scénáře"}
              value={scenario.title}
              onChange={handleChange}
              size={"small"}
              sx={{ ml: 1, width: 500 }}
            />
            <FormControl sx={{ ml: 1 }}>
              <InputLabel>Zobrazení při vyplňování</InputLabel>
              <Select
                name={"mode"}
                sx={{ width: 200 }}
                size={"small"}
                value={scenario.mode}
                label={"Zobrazení při vyplňování"}
                onChange={handleChange}
              >
                <MenuItem value={"SEQ"}>Postupně</MenuItem>
                <MenuItem value={"ALL"}>Vše najednou</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Divider />
          <ScenarioEditor />
        </Box>
        <Box
          sx={{
            display: tab === "reportBuilder" ? "flex" : "none",
            flexDirection: "column",
            flexGrow: 1,
            height: "100%",
            p: 2,
          }}
        >
          <ReportBuilder />
        </Box>
        <Box
          sx={{
            display: tab === "prescription" ? "flex" : "none",
            flexDirection: "column",
            flexGrow: 1,
            height: "100%",
            p: 2,
          }}
        >
          prescription
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box sx={{ flexGrow: 1 }}>
          <ScenarioExecutionDebugDialog scenario={scenario} />
        </Box>
        <Button
          onClick={handleSubmit}
          startIcon={<Save />}
          variant={"contained"}
        >
          Uložit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScenarioEditorDialog;
