import { NODE_HEIGHT, NODE_WIDTH } from "../constants/constants";
import { EdgeCoordinateType } from "../../../types/EdgeCoordinateType.type";
import { EdgeType } from "../../../types/EdgeType.type";

export const getNodeCenterCoords = (nodeId: string) => {
  const nodeEl = window.document.getElementById(["node", nodeId].join("_"));

  if (!nodeEl) {
    return {
      x: 0 + NODE_WIDTH / 2,
      y: 0 + NODE_HEIGHT / 2,
    };
  }

  return {
    x: nodeEl.offsetLeft + NODE_WIDTH / 2,
    y: nodeEl.offsetTop + NODE_HEIGHT / 2,
  };
};

export const calculateEdgeCoordinates = (edges: Record<string, EdgeType>) => {
  const edgeCoordinates: Record<string, EdgeCoordinateType> = {};

  Object.keys(edges).forEach((key) => {
    const edge = edges[key];

    const n1 = getNodeCenterCoords(edge.sourceId);
    const n2 = getNodeCenterCoords(edge.destinationId);

    const distance = Math.sqrt(
      Math.pow(n1.x - n2.x, 2) + Math.pow(n1.y - n2.y, 2)
    );

    // Get angle between points
    const angleRad = Math.atan2(n2.y - n1.y, n2.x - n1.x);
    //const angleDeg = (angleRad * 180) / Math.PI;

    // Move line by half of line height
    const movement = { x: 0, y: (-1 * 20) / 2 };

    edgeCoordinates[key] = {
      x: n1.x + movement.x,
      y: n1.y + movement.y,
      distance: distance,
      angleRad: angleRad,
    };
  });

  return edgeCoordinates;
};
