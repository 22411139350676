import { Box, Collapse } from "@mui/material";
import { ACTION, scenarioExecutionReducer } from "./utils/reducer.util";
import { buildScenarioExecutionState } from "./utils/builder.utils";
import { ScenarioType } from "../../../../types/ScenarioType.type";
import { ScenarioExecutionType } from "../../../../types/ScenarioExecutionType.type";
import {
  NODES_HIDDEN,
  SCENARIO_MODE,
  STEP_STATUS,
} from "./utils/constants.util";
import { useReducer, useState } from "react";
import {
  findDestinationIdForNodeId,
  getScenarioExecutionStatus,
} from "./utils/function.utils";
import ScenarioExecutionHeader from "./ScenarioExecutionHeader";
import ScenarioExecutionStep from "./ScenarioExecutionStep";
import { se_handleNotification } from "./utils/syncWithDb.util";

type ScenarioExecutionProps = {
  scenario: ScenarioType;
  scenarioExecution: ScenarioExecutionType | null;
};

const ScenarioExecution = (props: ScenarioExecutionProps) => {
  const { scenario, scenarioExecution } = props;
  const {
    title = "",
    nodes = {},
    edges = {},
    mode = SCENARIO_MODE.SEQ,
  } = scenario;

  const [scenarioExecutionState, dispatch] = useReducer(
    scenarioExecutionReducer,
    buildScenarioExecutionState(
      scenarioExecution as ScenarioExecutionType,
      mode as "SEQ" | "ALL"
    )
  );

  const [open, setOpen] = useState(false);

  const scenarioHeaderClick = () => {
    if (open) {
      setOpen(false);
    } else {
      if (scenarioExecutionState.progress.stepIds.length === 0) {
        const initialNode = Object.values(nodes).find(
          (n) => n.nodeTypeId === "start"
        );
        if (!!initialNode) {
          let nextNodeId = initialNode.id;
          if (mode === SCENARIO_MODE.ALL) {
            do {
              let stepId = [
                nextNodeId,
                scenarioExecutionState.progress.loopIndex,
              ].join("__");
              nextNodeId = handleStepSubmit(nextNodeId, true, stepId);
            } while (
              !!nextNodeId &&
              !!nodes[nextNodeId] &&
              nodes[nextNodeId].nodeTypeId !== "end"
            );
          }
          if (mode === SCENARIO_MODE.SEQ) {
            let stepId = [
              nextNodeId,
              scenarioExecutionState.progress.loopIndex,
            ].join("__");
            handleStepSubmit(nextNodeId, true, stepId);
          }
          setTimeout(() => {
            setOpen(true);
          }, 150);
        } else {
          //alert("CHYBA! Není iniciální krok!");
        }
      } else {
        setOpen(true);
      }
    }
  };

  const handleChangeExecutionValues = (stepId: string, newValue: any) => {
    const step = scenarioExecutionState.progress.stepsByIds[stepId];

    dispatch({
      type: ACTION.PROGRESS_STEP_SET,
      payload: { ...step, value: newValue },
    });
  };

  const handleStepSubmit: any = (
    nodeId: string,
    setNext: boolean = true,
    stepId: string,
    nextLoopIndex: number | null = null
  ) => {
    const step = scenarioExecutionState.progress.stepsByIds[stepId];

    dispatch({
      type: ACTION.PROGRESS_STEP_SET,
      payload: { ...step, submittedAt: new Date().toISOString() },
    });

    if (setNext) {
      const destinationId = findDestinationIdForNodeId(
        edges,
        nodeId,
        !!step ? step.value : ""
      );

      if (!!destinationId) {
        const nextNode = nodes[destinationId];
        const loopIndex =
          nextLoopIndex === null
            ? scenarioExecutionState.progress.loopIndex
            : nextLoopIndex;

        let nextStepId = [nextNode.id, loopIndex].join("__");

        dispatch({
          type: ACTION.PROGRESS_STEP_ADD,
          payload: {
            step: { ...nextNode, id: nextStepId, nodeId: nextNode.id },
            scenarioExecution,
          },
        });

        if (["notification"].includes(nextNode.nodeTypeId)) {
          se_handleNotification({
            notification: nextNode.values,
            scenarioExecutionId: !!scenarioExecution
              ? scenarioExecution.id
              : null,
          });
        }

        let nli = null;
        if (["loopIncrementOne"].includes(nextNode.nodeTypeId)) {
          dispatch({ type: ACTION.PROGRESS_LOOP_INDEX_INCREMENT, payload: {} });
          nli = scenarioExecutionState.progress.loopIndex + 1;
        }

        if (NODES_HIDDEN.includes(nextNode.nodeTypeId)) {
          return handleStepSubmit(destinationId, setNext, "-1", nli);
        }
      } else {
        //alert("CHYBA! Nelze stanovit další krok!");
      }

      return destinationId;
    }
  };

  if (!scenarioExecutionState.progress.hasOwnProperty("stepsByIds")) {
    return <></>;
  }

  return (
    <Box
      sx={{
        background: "#ffffff",
        border: "solid 1px rgba(0, 0, 0, 0.12)",
      }}
    >
      <ScenarioExecutionHeader
        title={title}
        handleClick={() => {
          scenarioHeaderClick();
        }}
        status={getScenarioExecutionStatus(scenarioExecutionState)}
        validFrom={scenarioExecution?.validFrom || null}
        validTo={scenarioExecution?.validTo || null}
      />
      <Collapse in={open}>
        {scenarioExecutionState.progress.stepIds
          .filter((stepId: any) => {
            const step = scenarioExecutionState.progress.stepsByIds[stepId];
            return !["start"].includes(step.nodeTypeId);
          })
          .map((stepId: any) => {
            const step = scenarioExecutionState.progress.stepsByIds[stepId];
            const node = nodes[step.nodeId];
            const executionValue = step.value;
            return (
              <>
                <ScenarioExecutionStep
                  key={stepId}
                  stepStatus={
                    step.submittedAt === null
                      ? STEP_STATUS.ENABLED
                      : STEP_STATUS.DISABLED
                  }
                  node={node}
                  executionValue={executionValue}
                  handleUpdate={(newValue: any) => {
                    handleChangeExecutionValues(stepId, newValue);
                  }}
                  handleSubmit={() => {
                    handleStepSubmit(
                      step.nodeId,
                      mode === SCENARIO_MODE.SEQ,
                      stepId
                    );
                  }}
                />
              </>
            );
          })}
      </Collapse>
    </Box>
  );
};

export default ScenarioExecution;
