import { Button, Dialog, DialogContent } from "@mui/material";
import { useEffect, useState } from "react";
import { ScenarioType } from "../../../types/ScenarioType.type";
import { buildEntity } from "../../scenarioExecution/builder/builder";
import { ScenarioExecutionType } from "../../../types/ScenarioExecutionType.type";
import { Bolt } from "@mui/icons-material";
import ScenarioExecution from "./ScenarioExecution/ScenarioExecution";

const ScenarioExecutionDebugDialog = ({
  scenario,
}: {
  scenario: ScenarioType;
}) => {
  const [open, setOpen] = useState(false);
  const [scenarioExecution, setScenarioExecution] =
    useState<ScenarioExecutionType | null>(null);

  const buildScenarioExecution = (scenario: ScenarioType) => {
    const dt = new Date();
    const dt2 = new Date();
    dt.setHours(0, 0, 0, 0);
    dt2.setHours(23, 59, 59, 0);
    const scenarioExecution = buildEntity();
    // @ts-ignore
    scenarioExecution.scenarioId = scenario.id;
    // @ts-ignore
    scenarioExecution.unitId = null;
    // @ts-ignore
    scenarioExecution.executionValues = [];
    scenarioExecution.validFrom = dt.toISOString();
    scenarioExecution.validTo = dt2.toISOString();

    return scenarioExecution;
  };

  const handleOpen = () => {
    setScenarioExecution(buildScenarioExecution(scenario));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!open) {
      setScenarioExecution(null);
    }
  }, [open]);

  return (
    <>
      <Button
        variant={"outlined"}
        color={"secondary"}
        startIcon={<Bolt />}
        onClick={() => {
          handleOpen();
        }}
      >
        Vyzkoušet scénář
      </Button>
      <Dialog
        open={open && !!scenarioExecution}
        onClose={() => {
          handleClose();
        }}
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogContent>
          {!!scenario && !!scenarioExecution && (
            <ScenarioExecution
              scenario={scenario}
              scenarioExecution={scenarioExecution}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ScenarioExecutionDebugDialog;
