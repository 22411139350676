import { useState } from "react";
import {
  Alert,
  Box,
  Container,
  CssBaseline,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useAppDispatch } from "../../../../app/hooks";
import { tryLogin } from "../../../../api/api";
import { signInSuccess } from "../../utils/auth.utils";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

const SignIn = () => {
  const dispatch = useAppDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  const navigate = useNavigate();

  const handleLogin = () => {
    setError("");
    setLoading(true);
    tryLogin({
      params: {
        credentials: {
          username: username.trim(),
          password: password.trim(),
        },
      },
    })
      .then((response) => {
        if (response.status === 200) {
          dispatch(signInSuccess(response, navigate));
        } else {
          setError("Nepodařilo se přihlásit.");
        }
      })
      .catch((e) => {
        setError("Nepodařilo se přihlásit.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ mt: 8 }}>
      <CssBaseline />
      <Paper sx={{ border: "1px solid #eee", mb: 4 }} elevation={0}>
        <Box p={3}>
          <Typography
            color={"primary"}
            component="h1"
            variant="h3"
            fontWeight={"500"}
            mb={2}
            textAlign="center"
          >
            Kontrolní listy <small>(administrace)</small>
          </Typography>
          <TextField
            disabled={loading}
            label="Přihlašovací jméno"
            variant="outlined"
            value={username}
            fullWidth
            margin="normal"
            onChange={(e) => setUsername(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <TextField
            disabled={loading}
            type={"password"}
            label="Heslo"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          {error !== "" && <Alert severity={"error"}>{error}</Alert>}
          <LoadingButton
            loading={loading}
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleLogin}
            sx={{ mt: 2 }}
          >
            Přihlásit se
          </LoadingButton>
        </Box>
      </Paper>

      <Typography
        sx={{
          color: "#66788a",
          textAlign: "center",
          marginTop: 1,
          opacity: ".3",
          fontSize: 12,
        }}
        variant="body1"
      >
        <code>{process.env.REACT_APP_VERSION}</code>
      </Typography>
    </Container>
  );
};

export default SignIn;
