import React from "react";
import {
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { scenarioEditorSlice } from "../scenarioEditorSlice";
import Toolbar from "./Toolbar";
import Canvas from "./Canvas";
import { grey } from "@mui/material/colors";
import NodeDialog from "./NodeDialog";
import EdgeDialog from "./EdgeDialog";
import { buildEdge } from "../builders/edge.builder";

const ScenarioEditor = () => {
  const dispatch = useDispatch();

  const [activeId, setActiveId] = React.useState<any>(null);

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 8,
    },
  });
  const sensors = useSensors(pointerSensor);

  const handleDragStart = (event: any) => {
    const { active } = event;

    setActiveId(active.id);

    const ids = active.id.split("_");

    if (ids[0] === "node" && ids.length === 2) {
      const id = ids[1];
      dispatch(
        scenarioEditorSlice.actions.updateNodeCoordinate({
          id: id,
          delta: {
            x: 0,
            y: 0,
            z: 4,
          },
        })
      );
    }
  };

  const handleDragEnd = (event: any) => {
    const { active, delta, over } = event;

    const ids = active.id.split("_");

    //pohyboval se templateNode
    // if (ids[0] === "templateNode") {
    //   const node: NodeType = buildNode(ids[1]);
    //   const nodeCoordinate: NodeCoordinateType = {
    //     x: delta.x - NODE_WIDTH,
    //     y: delta.y,
    //     z: 0,
    //   };
    //   dispatch(scenarioEditorSlice.actions.addNode({ node, nodeCoordinate }));
    // }

    //pohyboval se node
    if (ids[0] === "node" && ids.length === 2) {
      const id = ids[1];

      dispatch(
        scenarioEditorSlice.actions.updateNodeCoordinate({
          id: id,
          delta: {
            x: delta.x,
            y: delta.y,
            z: 3,
          },
        })
      );
    }

    //pohyboval se edge
    if (
      !!active &&
      !!over &&
      active.id.split("_").length === 1 &&
      active.id.split("_")[0] !== "templateNode" &&
      over.id.split("_").length === 2
    ) {
      dispatch(
        scenarioEditorSlice.actions.addEdge(
          buildEdge(active.id.split("_")[0], over.id.split("_")[1])
        )
      );
    }

    setActiveId(null);
  };

  return (
    <>
      <DndContext
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        sensors={sensors}
        //modifiers={[restrictToParentElement]}
      >
        <Box sx={{ display: "flex", flexGrow: 1 }}>
          <Box
            sx={{
              flexGrow: 1,
              background: grey[50],
              position: "relative",
              overflow: "scroll",
            }}
          >
            <Toolbar />
            <Canvas />
          </Box>
        </Box>
        <DragOverlay>
          {!!activeId && activeId.split("_").length === 1 && (
            <Box
              sx={{
                width: 20,
                height: 20,
                background: "black",
                borderRadius: "50%",
              }}
            />
          )}
        </DragOverlay>
      </DndContext>
      <NodeDialog />
      <EdgeDialog />
    </>
  );
};

export default ScenarioEditor;
