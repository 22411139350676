import { ManageHistory, Route, Store, Summarize } from "@mui/icons-material";

const menuItems = {
  scenarioExecutions: {
    label: "Reporty",
    icon: Summarize,
    path: "/reports",
  },
  unit: {
    label: "Střediska",
    icon: Store,
    path: "/units",
  },
  scenario: {
    label: "Scénáře",
    icon: Route,
    path: "/scenarios",
  },
  scenarioPrescription: {
    label: "Předpisy",
    icon: ManageHistory,
    path: "/scenario-prescriptions",
  },
};

// export const menuItems = {
//   execution: {
//     code: "execution",
//     label: "Report",
//     icon: <History />,
//     component: () => {
//       return <ScenarioExecution />;
//     },
//   },
//   prescription: {
//     code: "prescription",
//     label: "Předpisy vyplnění",
//     icon: <ManageHistory />,
//     component: () => {
//       return <CrudScenarioPrescription />;
//     },
//   },
//   scenario: {
//     code: "scenario",
//     label: "Scénáře",
//     icon: <RouteIcon />,
//     component: () => {
//       return <CrudScenario />;
//     },
//   },
//   unit: {
//     code: "unit",
//     label: "Střediska",
//     icon: <Store />,
//     component: () => {
//       return <CrudUnit />;
//     },
//   },
//   session: {
//     code: "session",
//     label: "Debug",
//     icon: <BugReportIcon />,
//     component: () => {
//       return <ExecutionSessionDebug />;
//     },
//   },
// };

export default menuItems;
