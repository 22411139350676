import { Box, Chip, IconButton, Typography } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import React from "react";
import { formatDatetime } from "./utils/datetime.util";
import { SCENARIO_EXECUTION_STATUS_PROPS } from "./utils/constants.util";

interface Props {
  title: string;
  handleClick: any;
  status: string;
  validFrom: string | undefined | null;
  validTo: string | undefined | null;
}

const ScenarioExecutionHeader = (props: Props) => {
  const { title, handleClick, status, validFrom, validTo } = props;

  const statusProps = SCENARIO_EXECUTION_STATUS_PROPS[status];

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        alignItems: "center",
        backgroundColor: statusProps.background,
      }}
      onClick={handleClick}
    >
      <Box sx={{ flexGrow: 2, mr: 2 }}>
        {!!validFrom && !!validTo && (
          <Typography sx={{ fontSize: "75%" }}>
            {formatDatetime(validFrom)} - {formatDatetime(validTo)}
          </Typography>
        )}
        <Typography variant={"h6"}>{title}</Typography>
      </Box>
      <Box sx={{ mr: 1 }}>
        <Chip
          label={statusProps.label}
          sx={{ background: statusProps.color }}
        />
      </Box>
      <Box>
        <IconButton size={"small"}>
          <ArrowDropDown />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ScenarioExecutionHeader;
