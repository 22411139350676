import { entityAdapter } from "../slice/slice";
import { RootState } from "../../../app/store";
import { ENTITY_NAME } from "../config";
import { createSelector } from "@reduxjs/toolkit";
import { nodeTypesInputs } from "../../../enums/nodeType.enum";
import { scenarioComparatorFn } from "../../../utils/scenarioCodeComparatorFn.util";

const selectors = entityAdapter.getSelectors(
  (state: RootState) => state[ENTITY_NAME].entities
);

export const selectSelected = (state: any) => {
  return state[ENTITY_NAME].selected;
};

export const selectSelectedMode = (state: any) => {
  return state[ENTITY_NAME].selectedMode;
};

export const selectAll = (state: any) => {
  return selectors.selectAll(state);
};

export const selectById = (state: any, args: { id: string }) => {
  return selectors.selectById(state, args.id);
};

export const selectEntities = (state: any) => {
  return selectors.selectEntities(state);
};

export const selectAllSorted = createSelector([selectAll], (all) => {
  return all.sort(scenarioComparatorFn);
});

export const selectSelectedScenarioNodesForReportBuilder = (state: any) => {
  return Object.values(state?.scenarioEditor?.scenario?.nodes || {}).filter(
    (n: any) => {
      return nodeTypesInputs.includes(n.nodeTypeId);
    }
  );
};

export const selectSelectedScenarioReportHeaders = createSelector(
  [selectSelected],
  (scenario) => {
    if (scenario && !!scenario.reportHeaders) {
      return scenario.reportHeaders;
    }
    return [];
  }
);
