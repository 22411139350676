import { AddCircle } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { useAppDispatch } from "../../../../app/hooks";
import { create } from "../../actions/actions";

const ButtonCreate = () => {
  const dispatch = useAppDispatch();

  const handleCreate = () => {
    dispatch(create());
  };

  return (
    <Button
      sx={{ mb: 2 }}
      variant={"contained"}
      color={"primary"}
      startIcon={<AddCircle />}
      onClick={() => {
        handleCreate();
      }}
    >
      Přidat scénář
    </Button>
  );
};

export default ButtonCreate;
