import React from "react";
import { selectAllFlattenByProfile } from "../selectors/selectors";
import { useAppSelector } from "../../../app/hooks";
import CrudList from "../../common/components/CrudList/CrudList";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid";
import axios from "../../../services/axios";
import { BASE_URL, ENTITY_TYPE } from "../config";
import { buildHeaders } from "../../../api/api";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import { Tooltip } from "@mui/material";

const List = () => {
  const items = useAppSelector(selectAllFlattenByProfile);

  const handleDownload = (entity: ENTITY_TYPE, reportType: string) => {
    const query = [
      ["scenario_id", entity.scenarioId].join("="),
      ["unit_id", entity.unitId].join("="),
      ["month", entity.month].join("="),
      ["year", entity.year].join("="),
      ["type", reportType].join("="),
    ].join("&");
    const url = BASE_URL + "/download?" + query;
    axios
      .get(url, {
        responseType: "blob",
        headers: buildHeaders({}),
      })
      .then((response) => {
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "Report.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });

    //axios.get(url).then((response) => {});
  };

  const columns: GridColDef[] = [
    {
      field: "unit.storeNumber",
      headerName: "Středisko",
      flex: 2,
      valueGetter: (params) => params.row?.unit?.storeNumber,
    },
    {
      field: "scenario.title",
      headerName: "Scénář",
      flex: 2,
      valueGetter: (params) =>
        params.row?.scenario?.code + ": " + params.row?.scenario?.title,
    },
    {
      field: "yearmonth",
      headerName: "Rok a měsíc",
      flex: 1,
      valueGetter: (params) =>
        [params.row?.year, String(params.row?.month).padStart(2, "0")].join(
          "/"
        ),
    },
    {
      field: "count_total",
      headerName: "K vyplnění",
      flex: 1,
    },
    {
      field: "count_executed",
      headerName: "Vyplněno",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Akce",
      flex: 1,
      type: "actions",
      sortable: false,
      filterable: false,
      hideable: false,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={
            <Tooltip title="Stáhnout report">
              <FileOpenOutlinedIcon />
            </Tooltip>
          }
          onClick={() => {
            handleDownload(params.row, "basic");
          }}
          label={"Stáhnout"}
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title="Stáhnout kompletní report">
              <FileOpenIcon />
            </Tooltip>
          }
          onClick={() => {
            handleDownload(params.row, "full");
          }}
          label={"Stáhnout"}
        />,
      ],
    },
  ];

  return <CrudList columns={columns} items={items} />;
};

export default List;
