import { createBrowserRouter } from "react-router-dom";
import { default as CrudScenario } from "../features/./scenario/components/Crud";
import { default as CrudUnit } from "../features/./unit/components/Crud";
import { default as CrudScenarioPrescription } from "../features/./scenarioPrescription/components/Crud";
import { default as CrudScenarioExecutionReport } from "../features/./scenarioExecutionReport/components/Crud";
import Layout from "../features/common/components/Layout/Layout";
import SignIn from "../features/auth/components/SignIn/SignIn";

const router = createBrowserRouter([
  {
    path: "/",
    element: <SignIn />,
  },
  {
    path: "/reports",
    element: (
      <Layout>
        <CrudScenarioExecutionReport />
      </Layout>
    ),
  },
  {
    path: "/scenarios",
    element: (
      <Layout>
        <CrudScenario />
      </Layout>
    ),
  },
  {
    path: "/units",
    element: (
      <Layout>
        <CrudUnit />
      </Layout>
    ),
  },
  {
    path: "/scenario-prescriptions",
    element: (
      <Layout>
        <CrudScenarioPrescription />
      </Layout>
    ),
  },
]);

export default router;
