import { authSlice } from "../slice/slice";
import { RootState } from "../../../app/store";
import { createSelector } from "@reduxjs/toolkit";

export const selectAuth = (state: RootState) => {
  return state[authSlice.name];
};

export const selectProfile = createSelector([selectAuth], (auth) => {
  return auth.profile;
});
