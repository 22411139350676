import axios from "axios";
import { logOut } from "../features/auth/utils/auth.utils";
import { store } from "../app/store";

axios.interceptors.response.use(
  (response: any) => {
    if (response.status === 401) {
      logOut(store.dispatch);
    } else {
      return response;
    }
  },
  (error: any) => {
    if (error.response && error.response.data) {
      if (error.response.status === 401) {
        logOut(store.dispatch);
      } else {
        return Promise.reject(error.response.data);
      }
    } else {
      return Promise.reject(error.message);
    }
  }
);

export default axios;
