import { entityAdapter } from "../slice/slice";
import { RootState } from "../../../app/store";
import { ENTITY_NAME } from "../config";
import { createSelector } from "@reduxjs/toolkit";
import { selectProfile } from "../../auth/selectors/auth.selector";

const selectors = entityAdapter.getSelectors(
  (state: RootState) => state[ENTITY_NAME].entities
);

export const selectSelected = (state: any) => {
  return state[ENTITY_NAME].selected;
};

export const selectSelectedMode = (state: any) => {
  return state[ENTITY_NAME].selectedMode;
};

export const selectAll = (state: any) => {
  return selectors.selectAll(state);
};

export const selectById = (state: any, args: { id: string }) => {
  return selectors.selectById(state, args.id);
};

export const selectEntities = (state: any) => {
  return selectors.selectEntities(state);
};

export const selectAllByProfile = createSelector(
  [selectAll, selectProfile],
  (entities, profile) => {
    if (!profile) {
      return [];
    }
    const unitIdsByProfile = profile.units.map((u) => u.id);
    return entities.filter((u) => unitIdsByProfile.includes(u.id));
  }
);
