import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  TextField,
} from "@mui/material";
import { scenarioEditorSlice } from "../scenarioEditorSlice";
import { edgeConditionOperationTypes } from "../builders/edge.builder";
import React from "react";
import { Delete, Save } from "@mui/icons-material";
import { EdgeType } from "../../../types/EdgeType.type";

const EdgeDialog = () => {
  const dispatch = useDispatch();
  const selectedEdgeId = useSelector(
    (state: RootState) => state.scenarioEditor.selectedEdgeId
  );
  const edge: EdgeType | null = useSelector((state: RootState) =>
    !!selectedEdgeId && !!state.scenarioEditor.scenario
      ? state.scenarioEditor.scenario.edges[selectedEdgeId]
      : null
  );

  if (!selectedEdgeId || !edge) {
    return <></>;
  }

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    dispatch(
      scenarioEditorSlice.actions.updateEdge({
        edge: {
          ...edge,
          values: {
            ...edge.values,
            condition: {
              ...edge.values.condition,
              [name]: value,
            },
          },
        },
      })
    );
  };

  const handleDelete = () => {
    dispatch(scenarioEditorSlice.actions.deleteEdge({ id: edge.id }));
    dispatch(scenarioEditorSlice.actions.setSelectedEdgeId(null));
  };

  const getHelperText = () => {
    const operation = edge.values.condition.operation;
    if (edgeConditionOperationTypes.hasOwnProperty(operation)) {
      return edgeConditionOperationTypes[operation].description;
    }
    return "";
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={true}
      onClose={() =>
        dispatch(scenarioEditorSlice.actions.setSelectedEdgeId(null))
      }
    >
      <DialogContent sx={{ p: 2, pt: 3 }}>
        <Box>Zadejte podmínku, která musí platit pro pokračování scénáře.</Box>
        <Box sx={{ mt: 3, display: "flex" }}>
          <TextField
            select
            label="Operace"
            value={edge.values.condition.operation}
            name={"operation"}
            onChange={handleChange}
            sx={{ width: 300, mr: 2 }}
          >
            {Object.entries(edgeConditionOperationTypes).map(([id, type]) => (
              <MenuItem key={id} value={id}>
                {type.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label={"Hodnota"}
            fullWidth={true}
            autoFocus={true}
            name={"value"}
            value={edge.values.condition.value}
            onChange={handleChange}
            helperText={getHelperText()}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{ px: 2, pb: 2, display: "flex", justifyContent: "start" }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Button
            onClick={() => {
              handleDelete();
            }}
            size={"small"}
            startIcon={<Delete />}
            variant={"outlined"}
            color={"error"}
          >
            Odstranit
          </Button>
        </Box>
        <Box>
          <Button
            onClick={() => {
              dispatch(scenarioEditorSlice.actions.setSelectedEdgeId(null));
            }}
            size={"small"}
            startIcon={<Save />}
            variant={"contained"}
          >
            Uložit
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default EdgeDialog;
