import { Box } from "@mui/material";
import React from "react";
import List from "./List";
import { useAppDispatch } from "../../../app/hooks";
import { load } from "../actions/actions";
import DialogDelete from "./dialogs/DialogDelete";
import DialogDetail from "./dialogs/DialogDetail";
import ButtonCreate from "./buttons/ButtonCreate";

const Crud = () => {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(load());
  }, []);

  return (
    <>
      <Box sx={{ textAlign: "right" }}>
        <ButtonCreate />
      </Box>

      <List />
      <DialogDelete />
      <DialogDetail />
    </>
  );
};

export default Crud;
