import { v4 as uuidv4 } from "uuid";
import { ENTITY_TYPE } from "../config";

export const buildEntity = (): ENTITY_TYPE => {
  return {
    id: uuidv4(),
    title: "",
    nodes: {},
    nodeCoordinates: {},
    edges: {},
    edgeCoordinates: {},
    code: "",
    enabled: true,
    reportHeaders: [],
    mode: "SEQ",
  };
};
