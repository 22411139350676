import { AppDispatch } from "../../../app/store";
import { NavigateFunction } from "react-router-dom";
import axios from "../../../services/axios";
import { authSlice } from "../slice/slice";

export const loadAuthToken = (): string | null => {
  return localStorage.getItem("authToken");
};

export const saveAuthToken = (value: string) => {
  localStorage.setItem("authToken", value);
};

export const logOut = (dispatch: AppDispatch) => {
  localStorage.removeItem("authToken");
  dispatch(authSlice.actions.setProfile(null));
};

export const signInSuccess =
  (response: Response, navigate: NavigateFunction) =>
  async (dispatch: AppDispatch) => {
    //@ts-ignore
    const { token, profile } = response.data;

    await saveAuthToken(token);
    fetchProfile(token).then((response: Response) => {
      if (response.status === 200) {
        //@ts-ignore
        const data = response.data;
        dispatch(authSlice.actions.setProfile(data));
        navigate("/reports");
      }
    });
  };

export const fetchProfile = (authToken: string): Promise<Response> => {
  return axios.get(process.env.REACT_APP_ENDPOINT_SERVER + "/api/profile", {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};
