import { Autocomplete, Checkbox, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type MuiAutocompleteProps = {
  value: any[];
  label: string;
  options: any[];
  getOptionLabel: (option: any) => string;
  onChange: (newValue: any[]) => void;
};

const MuiAutocomplete = (props: MuiAutocompleteProps) => {
  const { value, onChange, label, options, getOptionLabel } = props;

  return (
    <Autocomplete
      sx={{ mt: 2 }}
      multiple
      value={options.filter((o) =>
        o.hasOwnProperty("id") ? value.includes(o.id) : value.includes(o)
      )}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      options={options}
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {getOptionLabel(option)}
        </li>
      )}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
};

export default MuiAutocomplete;
