import { entityAdapter } from "../slice/slice";
import { RootState } from "../../../app/store";
import { ENTITY_NAME } from "../config";

const selectors = entityAdapter.getSelectors(
  (state: RootState) => state[ENTITY_NAME].entities
);

export const selectSelected = (state: any) => {
  return state[ENTITY_NAME].selected;
};

export const selectSelectedMode = (state: any) => {
  return state[ENTITY_NAME].selectedMode;
};

export const selectAll = (state: any) => {
  return selectors.selectAll(state);
};
