import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import React, { useEffect, useState } from "react";
import { selectSelected, selectSelectedMode } from "../../selectors/selectors";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from "@mui/material";
import {
  confirmCreate,
  confirmUpdate,
  resetSelected,
  resetSelectedMode,
  setSelected,
} from "../../actions/actions";
import { ENTITY_TYPE } from "../../config";
import { unwrapResult } from "@reduxjs/toolkit";
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import CloseButton from "../../../common/components/CloseButton/CloseButton";

const DialogDetail = () => {
  const dispatch = useAppDispatch();
  const mode = useAppSelector(selectSelectedMode);
  const entity = useAppSelector(selectSelected);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = (entity: ENTITY_TYPE) => {
    switch (mode) {
      case "create":
        {
          dispatch(confirmCreate(entity))
            .then(unwrapResult)
            .then((result: any) => {
              handleClose();
            });
        }
        break;
      case "update":
        {
          dispatch(confirmUpdate(entity))
            .then(unwrapResult)
            .then((result: any) => {
              handleClose();
            });
        }
        break;
    }
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    dispatch(setSelected({ ...entity, [name]: value }));
  };

  useEffect(() => {
    if (mode === "create" || mode === "update") {
      setOpen(true);
    }
  }, [mode]);

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        dispatch(resetSelectedMode());
        dispatch(resetSelected());
      }, 500);
    }
  }, [open]);

  if (!entity) {
    return <></>;
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        {mode === "create"
          ? "Nové středisko"
          : "Úprava střediska " + entity.storeNumber}
        <CloseButton onClick={handleClose} />
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <TextField
            fullWidth={true}
            label={"Číslo střediska"}
            value={entity.storeNumber}
            name={"storeNumber"}
            onChange={handleChange}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <TextField
            fullWidth={true}
            label={"Koncept"}
            value={entity.brandCode}
            name={"brandCode"}
            onChange={handleChange}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <TextField
            fullWidth={true}
            label={"E-mail"}
            value={entity.email}
            name={"email"}
            onChange={handleChange}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <TextField
            fullWidth={true}
            label={"E-mail FM"}
            value={entity.emailFieldManager}
            name={"emailFieldManager"}
            onChange={handleChange}
          />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant={"text"}
          onClick={() => {
            handleClose();
          }}
          startIcon={<CloseIcon />}
        >
          Zrušit
        </Button>
        <Button
          variant={"contained"}
          onClick={() => {
            handleConfirm(entity);
          }}
          startIcon={<SaveIcon />}
        >
          Uložit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDetail;
