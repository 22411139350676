import { v4 as uuidv4 } from "uuid";
import {
  blue,
  blueGrey,
  lightGreen,
  orange,
  red,
  yellow,
} from "@mui/material/colors";
import {
  Abc,
  AccessTime,
  CalendarToday,
  Campaign,
  Dns,
  Image,
  Loop,
  Numbers,
  PlayCircleFilled,
  QuestionMark,
  StopCircle,
  Visibility,
} from "@mui/icons-material";
import { NodeType } from "../../../types/NodeType.type";
import {
  NODE_TYPE__END,
  NODE_TYPE__INPUT_DATE,
  NODE_TYPE__INPUT_DATE_TIME,
  NODE_TYPE__INPUT_FROM_LIST,
  NODE_TYPE__INPUT_NUMERIC,
  NODE_TYPE__INPUT_TEXT,
  NODE_TYPE__INPUT_UPLOAD_IMAGE,
  NODE_TYPE__LOOP_INCREMENT_ONE,
  NODE_TYPE__NOTIFICATION,
  NODE_TYPE__START,
  NODE_TYPE__TEXT,
} from "../../../enums/nodeType.enum";

export const nodeTypes = {
  [NODE_TYPE__START]: {
    label: "Start",
    description:
      "Pouze pro určení začátku scénáře. V jednom scénáři může být právě jeden Start.",
    color: lightGreen,
    icons: [PlayCircleFilled],
    fields: {},
  },
  [NODE_TYPE__TEXT]: {
    label: "Text",
    description:
      "Použijte, pokud chcete uživateli poskytnout informaci, nebo udělit pokyn.",
    color: yellow,
    icons: [Visibility],
    fields: {
      customId: {
        autoFocus: false,
        label: "Identifikátor",
        helperText:
          "Identifikátor slouží pro identifikaci pole pro reporty. Identifikátor by se neměl měnit.",
      },
      displayText: {
        autoFocus: true,
        label: "Text",
      },
    },
  },
  [NODE_TYPE__INPUT_TEXT]: {
    label: "Textová hodnota",
    description: "Použijte, pokud chcete, aby uživatel zadal textovou hodnotu.",
    color: orange,
    icons: [QuestionMark, Abc],
    fields: {
      customId: {
        autoFocus: false,
        label: "Identifikátor",
        helperText:
          "Identifikátor slouží pro identifikaci pole pro reporty. Identifikátor by se neměl měnit.",
      },
      displayText: {
        autoFocus: true,
        label: "Pokyn",
      },
    },
  },
  [NODE_TYPE__INPUT_NUMERIC]: {
    label: "Číselná hodnota",
    description: "Použijte, pokud chcete, aby uživatel zadal číselnou hodnotu.",
    color: orange,
    icons: [QuestionMark, Numbers],
    fields: {
      customId: {
        autoFocus: false,
        label: "Identifikátor",
        helperText:
          "Identifikátor slouží pro identifikaci pole pro reporty. Identifikátor by se neměl měnit.",
      },
      displayText: {
        autoFocus: true,
        label: "Pokyn",
      },
    },
  },
  [NODE_TYPE__INPUT_FROM_LIST]: {
    label: "Výběr možnosti ze seznamu",
    description:
      "Použijte, pokud chcete, aby uživatel si vybral jednu možnost ze seznamu.",
    color: orange,
    icons: [QuestionMark, Dns],
    fields: {
      customId: {
        autoFocus: false,
        label: "Identifikátor",
        helperText:
          "Identifikátor slouží pro identifikaci pole pro reporty. Identifikátor by se neměl měnit.",
      },
      displayText: {
        autoFocus: true,
        label: "Pokyn",
      },
      options: {
        label: "Možnosti",
        helperText: "Možnosti oddělujte klávesou Enter",
      },
    },
  },
  [NODE_TYPE__INPUT_DATE]: {
    label: "Datum",
    description: "Použijte, pokud chcete, aby uživatel zadal datum.",
    color: orange,
    icons: [QuestionMark, CalendarToday],
    fields: {
      customId: {
        autoFocus: false,
        label: "Identifikátor",
        helperText:
          "Identifikátor slouží pro identifikaci pole pro reporty. Identifikátor by se neměl měnit.",
      },
      displayText: {
        autoFocus: true,
        label: "Pokyn",
      },
    },
  },
  [NODE_TYPE__INPUT_DATE_TIME]: {
    label: "Datum a čas",
    description: "Použijte, pokud chcete, aby uživatel zadal datum a čas",
    color: orange,
    icons: [QuestionMark, AccessTime],
    fields: {
      customId: {
        autoFocus: false,
        label: "Identifikátor",
        helperText:
          "Identifikátor slouží pro identifikaci pole pro reporty. Identifikátor by se neměl měnit.",
      },
      displayText: {
        autoFocus: true,
        label: "Pokyn",
      },
    },
  },
  [NODE_TYPE__INPUT_UPLOAD_IMAGE]: {
    label: "Nahrání fotografie",
    description: "Použijte, pokud chcete, aby uživatel nahrál fotografii.",
    color: orange,
    icons: [QuestionMark, Image],
    fields: {
      customId: {
        autoFocus: false,
        label: "Identifikátor",
        helperText:
          "Identifikátor slouží pro identifikaci pole pro reporty. Identifikátor by se neměl měnit.",
      },
      displayText: {
        autoFocus: true,
        label: "Pokyn",
      },
    },
  },
  [NODE_TYPE__NOTIFICATION]: {
    label: "Upozornění",
    description: "Použijte, pokud chcete, aby bylo odesláno upozornění",
    color: red,
    icons: [Campaign],
    getLabel: (node: NodeType) => {
      switch (node.values.sendTo) {
        case "fieldManager": {
          return "Regionální manažer střediska";
        }
        case "other": {
          return node.values.recipientEmail;
        }
      }
      return "";
    },
    fields: {
      sendTo: {
        type: "select",
        autoFocus: false,
        label: "Odeslat na koho?",
        options: [
          {
            value: "fieldManager",
            label: "Regionální manažer střediska",
          },
          {
            value: "other",
            label: "Jiný příjemce",
          },
        ],
        helperText: "Na koho má být upozornění odesláno?",
      },
      recipientEmail: {
        autoFocus: false,
        label: "E-mail příjemce",
        helperText:
          "Vyplňte e-mail příjemce pouze v případě, kdy má být upozornění odesláno na jiného příjemce.",
      },
      sendWhen: {
        type: "select",
        autoFocus: false,
        label: "Odeslat kdy?",
        options: [
          {
            value: "afterEndOfNode",
            label: "Okamžitě",
          },
          {
            value: "afterEndOfScenario",
            label: "Po vyplnění formuláře",
          },
        ],
        helperText: "Kdy má být upozornění odesláno?",
      },
    },
  },
  [NODE_TYPE__LOOP_INCREMENT_ONE]: {
    label: "Opakování (+1)",
    description: "Použijte, pokud chcete, aby postup formulářem byl opakován",
    color: blue,
    icons: [Loop],
    fields: {},
  },
  [NODE_TYPE__END]: {
    label: "Konec",
    description:
      "Ukončuje scénář a zobrazí uživateli závěrečný text (např. poděkování). Scénář může mít více Konců.",
    color: blueGrey,
    icons: [StopCircle],
    fields: {
      displayText: {
        autoFocus: true,
        label: "Závěrečný text",
      },
    },
  },
};

export const buildNode = (
  nodeTypeId: keyof typeof nodeTypes = NODE_TYPE__TEXT
): NodeType => {
  return {
    id: uuidv4(),
    nodeTypeId: nodeTypeId,
    values: {},
  };
};
