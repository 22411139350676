import { green, orange, red } from "@mui/material/colors";

export const INPUT_WIDTH = 230;

export const NODES_HIDDEN = ["notification", "loopIncrementOne"];

export const NODES_INPUT = [
  "inputNumeric",
  "inputFromList",
  "inputUploadImage",
  "inputText",
  "inputDate",
  "inputDateTime",
];

export const SCENARIO_MODE = {
  SEQ: "SEQ",
  ALL: "ALL",
};

export const SCENARIO_EXECUTION_STATUS = {
  NOT_COMPLETED: "notCompleted",
  IN_PROGRESS: "inProgress",
  COMPLETED: "completed",
};

export const STEP_STATUS = {
  ENABLED: "enabled",
  DISABLED: "disabled",
};

export const SCENARIO_EXECUTION_STATUS_PROPS = {
  [SCENARIO_EXECUTION_STATUS.NOT_COMPLETED]: {
    label: "Není vyplněno",
    background: red[50],
    color: red[200],
  },
  [SCENARIO_EXECUTION_STATUS.IN_PROGRESS]: {
    label: "Rozpracováno",
    background: orange[50],
    color: orange[200],
  },
  [SCENARIO_EXECUTION_STATUS.COMPLETED]: {
    label: "Vyplněno",
    background: green[50],
    color: green[200],
  },
};
