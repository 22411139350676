import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { calculateEdgeCoordinates } from "./utils/utils";
import { ScenarioType } from "../../types/ScenarioType.type";
import { NodeType } from "../../types/NodeType.type";
import { NodeCoordinateType } from "../../types/NodeCoordinateType.type";
import { EdgeType } from "../../types/EdgeType.type";

export type ScenarioEditorStateType = {
  scenario: ScenarioType | null;
  selectedNodeId: string | null;
  selectedEdgeId: string | null;
};

const initialState: ScenarioEditorStateType = {
  scenario: null,
  selectedNodeId: null,
  selectedEdgeId: null,
};

export const scenarioEditorSlice = createSlice({
  name: "scenarioEditor",
  initialState,
  reducers: {
    scenarioSet: (
      state: ScenarioEditorStateType,
      action: PayloadAction<ScenarioType | null>
    ) => {
      return { ...initialState, scenario: action.payload };
    },
    scenarioUpdate: (
      state: ScenarioEditorStateType,
      action: PayloadAction<{ name: keyof ScenarioType; value: any }>
    ) => {
      if (!!state.scenario) {
        //@ts-ignore
        state.scenario[action.payload.name] = action.payload.value;
      }
    },
    // nodeCreate: (
    //   state: ScenarioEditorStateType,
    //   action: PayloadAction<{
    //     node: NodeType;
    //     nodeCoordinate: NodeCoordinateType;
    //   }>
    // ) => {
    //   if (!!state.scenario) {
    //     state.scenario.nodes[action.payload.node.id] = action.payload.node;
    //     state.scenario.nodeCoordinates[action.payload.node.id] =
    //       action.payload.nodeCoordinate;
    //   }
    // },
    setScenario: (
      state: ScenarioEditorStateType,
      action: PayloadAction<ScenarioType | null>
    ) => {
      state.scenario = action.payload;
      state.selectedEdgeId = null;
      state.selectedEdgeId = null;
    },
    updateScenario: (
      state: ScenarioEditorStateType,
      action: PayloadAction<{ name: keyof ScenarioType; value: any }>
    ) => {
      if (!!state.scenario) {
        //@ts-ignore
        state.scenario[action.payload.name] = action.payload.value;
      }
    },
    addNode: (
      state: ScenarioEditorStateType,
      action: PayloadAction<{
        node: NodeType;
        nodeCoordinate: NodeCoordinateType;
      }>
    ) => {
      if (!!state.scenario) {
        state.scenario.nodes = {
          ...state.scenario.nodes,
          [action.payload.node.id]: action.payload.node,
        };
        state.scenario.nodeCoordinates = {
          ...state.scenario.nodeCoordinates,
          [action.payload.node.id]: action.payload.nodeCoordinate,
        };
      }
    },
    updateNode: (
      state: ScenarioEditorStateType,
      action: PayloadAction<{
        node: NodeType;
      }>
    ) => {
      if (!!state.scenario) {
        state.scenario.nodes = {
          ...state.scenario.nodes,
          [action.payload.node.id]: action.payload.node,
        };
      }
    },
    deleteNode: (
      state: ScenarioEditorStateType,
      action: PayloadAction<{ id: string }>
    ) => {
      if (!!state.scenario) {
        delete state.scenario.nodes[action.payload.id];
        delete state.scenario.nodeCoordinates[action.payload.id];
        const edgeIds = Object.values(state.scenario.edges)
          .filter((edge) => {
            return (
              edge.sourceId === action.payload.id ||
              edge.destinationId === action.payload.id
            );
          })
          .map((edge) => {
            return edge.id;
          });

        for (let i = 0; i < edgeIds.length; i++) {
          // @ts-ignore
          delete state.edges[edgeIds[i]];
          // @ts-ignore
          delete state.edgeCoordinates[edgeIds[i]];
        }
      }
    },
    updateNodeCoordinate: (
      state: ScenarioEditorStateType,
      action: PayloadAction<{
        id: string;
        delta: { x: number; y: number; z: number };
      }>
    ) => {
      if (!!state.scenario) {
        const copy = { ...state.scenario.nodeCoordinates[action.payload.id] };
        copy.x = copy.x + action.payload.delta.x;
        copy.y = copy.y + action.payload.delta.y;
        copy.z = copy.z + action.payload.delta.z;
        state.scenario.nodeCoordinates[action.payload.id] = copy;
      }
    },
    addEdge: (
      state: ScenarioEditorStateType,
      action: PayloadAction<EdgeType>
    ) => {
      if (!!state.scenario) {
        state.scenario.edges = {
          ...state.scenario.edges,
          [action.payload.id]: action.payload,
        };
      }
    },
    updateEdge: (
      state: ScenarioEditorStateType,
      action: PayloadAction<{
        edge: EdgeType;
      }>
    ) => {
      if (!!state.scenario) {
        state.scenario.edges = {
          ...state.scenario.edges,
          [action.payload.edge.id]: action.payload.edge,
        };
      }
    },
    deleteEdge: (
      state: ScenarioEditorStateType,
      action: PayloadAction<{ id: string }>
    ) => {
      if (!!state.scenario) {
        // @ts-ignore
        delete state.scenario.edges[action.payload.id];
        // @ts-ignore
        delete state.scenario.edgeCoordinates[action.payload.id];
      }
    },
    redrawEdges: (state) => {
      if (!!state.scenario) {
        state.scenario.edgeCoordinates = calculateEdgeCoordinates(
          state.scenario.edges
        );
      }
    },
    setSelectedNodeId: (
      state: ScenarioEditorStateType,
      action: PayloadAction<string | null>
    ) => {
      state.selectedNodeId = action.payload;
    },
    setSelectedEdgeId: (
      state: ScenarioEditorStateType,
      action: PayloadAction<string | null>
    ) => {
      state.selectedEdgeId = action.payload;
    },
  },
});
