import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { scenario } from "../features/scenario";
import { scenarioPrescription } from "../features/scenarioPrescription";
import { unit } from "../features/unit";
import { scenarioEditorSlice } from "../features/scenarioEditor/scenarioEditorSlice";
import { scenarioExecution } from "../features/scenarioExecution";
import { scenarioExecutionReport } from "../features/scenarioExecutionReport";
import { authSlice } from "../features/auth/slice/slice";

export const store = configureStore({
  reducer: {
    [authSlice.name]: authSlice.reducer,
    [scenario.slice.name]: scenario.slice.reducer,
    [scenarioPrescription.slice.name]: scenarioPrescription.slice.reducer,
    [scenarioExecution.slice.name]: scenarioExecution.slice.reducer,
    [scenarioExecutionReport.slice.name]: scenarioExecutionReport.slice.reducer,
    [unit.slice.name]: unit.slice.reducer,
    [scenarioEditorSlice.name]: scenarioEditorSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat();
  },
  devTools: true,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
