import axios from "../services/axios";
import { loadAuthToken } from "../features/auth/utils/auth.utils";

const baseUrl = process.env.REACT_APP_ENDPOINT_SERVER;

const buildHeaders = (defaults: any) => {
  const authToken = loadAuthToken();
  return { Authorization: `Bearer ${authToken}`, ...defaults };
};

const tryLogin = (props: any): Promise<Response> => {
  return axios.post(baseUrl + "/auth", {
    username: props.params.credentials.username,
    password: props.params.credentials.password,
    frontend: "admin",
  });
};

export { tryLogin, buildHeaders };
