import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  Hidden,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import menuItems from "../../../../app/menuItems";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectProfile } from "../../../auth/selectors/auth.selector";
import { fetchProfile, loadAuthToken } from "../../../auth/utils/auth.utils";
import { authSlice } from "../../../auth/slice/slice";
import SignIn from "../../../auth/components/SignIn/SignIn";
import LayoutAppBarProfile from "../ProfileAppBar/LayoutAppBarProfile";

const drawerWidth = 240;

const Layout = (props: any) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const profile = useAppSelector(selectProfile);

  const [loading, setLoading] = useState(() => {
    return !!loadAuthToken();
  });

  useEffect(() => {
    const token = loadAuthToken();
    if (!!token) {
      fetchProfile(token)
        .then((response: Response) => {
          if (response.status === 200) {
            //@ts-ignore
            const data = response.data;
            dispatch(authSlice.actions.setProfile(data));
          }
        })
        .catch((e) => {
          navigate("/");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  if (loading) {
    return <></>;
  }

  if (!profile) {
    return <SignIn />;
  }

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
        color="primary"
        sx={{
          borderBottom: "1px solid #dfe3e8",
          paddingTop: "env(safe-area-inset-top)",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          <Hidden smDown>
            <Box
              display="flex"
              alignItems="center"
              sx={{
                img: {
                  width: 100,
                  height: "auto",
                  mr: 3,
                },
              }}
            >
              <img
                alt="Lagardere Travel Retail"
                src="/lagardere-travel-retail-logo.svg"
              />
            </Box>
          </Hidden>
          <Typography
            variant="h6"
            component="div"
            color="#013063"
            sx={{ mr: 2, flexGrow: 1 }}
          >
            Kontrolní listy (administrace)
          </Typography>

          <Box
            sx={{
              marginLeft: "auto",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Box sx={{ mr: 3, mt: -1 }}>
              {/* <HeadwayWidget
                account="765vbx"
                translations={{
                  title: "Přehled změn",
                  readMore: "Číst více",
                  footer: "Číst více",
                }}
              /> */}
            </Box>
            <Box sx={{ color: "#66788a", textAlign: "right" }}>
              <LayoutAppBarProfile />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <Toolbar />

      <Box sx={{ mt: 0, display: "flex" }}>
        <Box>
          <Drawer
            variant="permanent"
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
          >
            <Toolbar />

            <MenuList>
              {Object.entries(menuItems).map(([key, value]) => {
                return (
                  <MenuItem
                    selected={value.path === pathname}
                    key={key}
                    component={Link}
                    to={value.path}
                  >
                    <ListItemIcon>
                      {React.createElement(value.icon)}
                    </ListItemIcon>
                    <ListItemText>{value.label}</ListItemText>
                  </MenuItem>
                );
              })}
            </MenuList>
          </Drawer>
        </Box>
        <Box sx={{ m: 2, flexGrow: 1 }}>{props.children}</Box>
      </Box>
    </>
  );
};

export default Layout;
