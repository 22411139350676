import { AppDispatch } from "../../../app/store";
import { scenario } from "../index";
import { buildEntity } from "../builder/builder";
import { scenarioEditorSlice } from "../.././scenarioEditor/scenarioEditorSlice";
import { ENTITY_TYPE } from "../config";
import { unwrapResult } from "@reduxjs/toolkit";

export const load = () => (dispatch: AppDispatch) => {
  dispatch(scenario.actions.getAll({}));
};

export const create = () => (dispatch: AppDispatch) => {
  const entity = buildEntity();
  dispatch(scenario.actions.setSelected(entity));
  dispatch(scenario.actions.setSelectedMode("create"));
  dispatch(scenarioEditorSlice.actions.setScenario(entity));
};

export const update = (entity: ENTITY_TYPE) => (dispatch: AppDispatch) => {
  dispatch(scenario.actions.setSelected(entity));
  dispatch(scenario.actions.setSelectedMode("update"));
  dispatch(scenarioEditorSlice.actions.setScenario(entity));
};

export const remove = (entity: ENTITY_TYPE) => (dispatch: AppDispatch) => {
  dispatch(scenario.actions.setSelected(entity));
  dispatch(scenario.actions.setSelectedMode("delete"));
};

export const resetSelected = () => (dispatch: AppDispatch) => {
  dispatch(scenario.actions.setSelected(null));
};

export const resetSelectedMode = () => (dispatch: AppDispatch) => {
  dispatch(scenario.actions.setSelectedMode(null));
};

export const confirmCreate = (data: any) => (dispatch: AppDispatch) => {
  dispatch(scenario.actions.post({ data }));
};

export const confirmUpdate = (data: any) => (dispatch: AppDispatch) => {
  dispatch(scenario.actions.put({ data }))
    .then(unwrapResult)
    .then((d) => {});
};

export const confirmDelete = (data: any) => (dispatch: AppDispatch) => {
  return dispatch(scenario.actions.delete({ data }));
};
