import { ScenarioType } from "../types/ScenarioType.type";

export const scenarioCodeComparatorFn = (a: string, b: string) => {
  // Pomocná funkce pro rozdělení řetězce na části
  function splitString(str: string) {
    const match = str.match(/(\D+|\d+)/g);
    // @ts-ignore
    return match.map((part) => (isNaN(part) ? part : parseInt(part, 10)));
  }

  const partsA = splitString(a);
  const partsB = splitString(b);

  // Porovnáváme části řetězce jednu po druhé
  for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
    const partA = partsA[i] || 0;
    const partB = partsB[i] || 0;

    if (partA === partB) {
      continue;
    }

    if (typeof partA === "number" && typeof partB === "number") {
      return partA - partB;
    }

    if (typeof partA === "string" && typeof partB === "string") {
      return partA.localeCompare(partB);
    }
  }

  return 0;
};

export const scenarioComparatorFn = (a: ScenarioType, b: ScenarioType) => {
  return scenarioCodeComparatorFn(a.code, b.code);
};
