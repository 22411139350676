import { AppDispatch } from "../../../app/store";
import { scenarioExecutionReport } from "../index";
// import { buildEntity } from "../builder/builder";
// import { ENTITY_TYPE } from "../config";

export const load = () => (dispatch: AppDispatch) => {
  dispatch(scenarioExecutionReport.actions.getAll({}));
};

// export const create = () => (dispatch: AppDispatch) => {
//   const entity = buildEntity();
//   dispatch(adminScenarioExecutionReport.actions.setSelected(entity));
//   dispatch(adminScenarioExecutionReport.actions.setSelectedMode("create"));
// };
//
// export const update = (entity: ENTITY_TYPE) => (dispatch: AppDispatch) => {
//   dispatch(adminScenarioExecutionReport.actions.setSelected(entity));
//   dispatch(adminScenarioExecutionReport.actions.setSelectedMode("update"));
// };
//
// export const remove = (entity: ENTITY_TYPE) => (dispatch: AppDispatch) => {
//   dispatch(adminScenarioExecutionReport.actions.setSelected(entity));
//   dispatch(adminScenarioExecutionReport.actions.setSelectedMode("delete"));
// };

// export const setSelected =
//   (newValue: ENTITY_TYPE) => (dispatch: AppDispatch) => {
//     dispatch(adminScenarioExecutionReport.actions.setSelected(newValue));
//   };
//
// export const resetSelected = () => (dispatch: AppDispatch) => {
//   dispatch(adminScenarioExecutionReport.actions.setSelected(null));
// };
//
// export const resetSelectedMode = () => (dispatch: AppDispatch) => {
//   dispatch(adminScenarioExecutionReport.actions.setSelectedMode(null));
// };
//
// export const confirmCreate = (data: any) => (dispatch: AppDispatch) => {
//   return dispatch(adminScenarioExecutionReport.actions.post({ data }));
// };
//
// export const confirmUpdate = (data: any) => (dispatch: AppDispatch) => {
//   return dispatch(adminScenarioExecutionReport.actions.put({ data }));
// };
//
// export const confirmDelete = (data: any) => (dispatch: AppDispatch) => {
//   return dispatch(adminScenarioExecutionReport.actions.delete({ data }));
// };
