import {
  Progress,
  ScenarioExecutionType,
} from "../../../../../types/ScenarioExecutionType.type";
import { ScenarioExecutionState } from "./reducer.util";

export const buildInitialProgress = (mode: "SEQ" | "ALL"): Progress => {
  return {
    loopIndex: 1,
    stepsByIds: {},
    stepIds: [],
    mode: mode,
  };
};

export const buildScenarioExecutionState = (
  scenarioExecution: ScenarioExecutionType,
  mode: "SEQ" | "ALL"
): ScenarioExecutionState => {
  return {
    scenarioExecutionId: scenarioExecution.id,
    error: "",
    progress:
      !!scenarioExecution.progress &&
      scenarioExecution.progress.hasOwnProperty("stepsByIds")
        ? scenarioExecution.progress
        : buildInitialProgress(mode),
  };
};
