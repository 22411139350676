import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

const ReportBuilderFormFieldPosition = (props: any) => {
  const { header, handleChange } = props;

  const renderPositionItems = () => {
    const positionItems = [];
    for (let i = 1; i <= 20; i++) {
      positionItems.push(
        <MenuItem key={i} value={i}>
          {i}
        </MenuItem>
      );
    }
    return positionItems;
  };

  return (
    <FormControl sx={{ width: 100, mr: 1 }}>
      <InputLabel>Pořadí</InputLabel>
      <Select
        label="Pořadí"
        value={header.position}
        onChange={(e) =>
          handleChange(header.id, "position", e.target.value as any)
        }
      >
        {renderPositionItems()}
      </Select>
    </FormControl>
  );
};

export default ReportBuilderFormFieldPosition;
