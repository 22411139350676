import { createSlice } from "@reduxjs/toolkit";

export type StateType = {
  profile: {
    username: string;
    units: { id: string; storeNumber: string; brandCode: string }[];
    brands: string[];
  } | null;
};

const initialState: StateType = {
  profile: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
  },
  extraReducers: (builder) => {},
});
