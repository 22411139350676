import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../services/axios";
import { BASE_URL, ENTITY_NAME } from "../config";
import { buildHeaders } from "../../../api/api";

const transformInput = (data: any) => {
  const { unitId, scenarioId, ...transformed } = data;
  transformed.unit = "/api/units/" + unitId;
  transformed.scenario = "/api/scenarios/" + scenarioId;
  return transformed;
};

const transformOutput = (data: any) => {
  const { unit, scenario, ...transformed } = data;
  transformed.unitId = unit.replace("/api/units/", "");
  transformed.scenarioId = scenario.replace("/api/scenarios/", "");
  return transformed;
};

export const thunks = {
  getAll: createAsyncThunk(
    ENTITY_NAME + "/getAll",
    async (arg: any, { rejectWithValue }) => {
      try {
        const response = await axios.get(BASE_URL, {
          headers: buildHeaders({
            "content-type": "application/ld+json",
          }),
        });
        if (response.status === 200) {
          return response.data["hydra:member"].map((r: any) => {
            return transformOutput(r);
          });
        } else {
          new Error();
        }
      } catch (error: any) {
        return rejectWithValue(error.message);
      }
    }
  ),
  post: createAsyncThunk(
    ENTITY_NAME + "/post",
    async (arg: any, { rejectWithValue }) => {
      try {
        const { data } = arg;
        const transformed = transformInput(data);
        const response = await axios.post(BASE_URL, transformed, {
          headers: buildHeaders({
            "content-type": "application/ld+json",
          }),
        });
        if (response.status === 201) {
          return transformOutput(response.data);
        } else {
          new Error();
        }
      } catch (error: any) {
        return rejectWithValue(error.message);
      }
    }
  ),
  put: createAsyncThunk(
    ENTITY_NAME + "/put",
    async (arg: any, { rejectWithValue }) => {
      try {
        const { data } = arg;
        const transformed = transformInput(data);
        const response = await axios.put(
          BASE_URL + "/" + data.id,
          transformed,
          {
            headers: buildHeaders({
              "content-type": "application/ld+json",
            }),
          }
        );
        if (response.status === 200) {
          return transformOutput(response.data);
        } else {
          new Error();
        }
      } catch (error: any) {
        return rejectWithValue(error.message);
      }
    }
  ),
  delete: createAsyncThunk(
    ENTITY_NAME + "delete",
    async (arg: any, { rejectWithValue }) => {
      try {
        const { id } = arg.data;
        const response = await axios.delete(BASE_URL + "/" + id, {
          headers: buildHeaders({
            "content-type": "application/ld+json",
          }),
        });
        if (response.status === 204) {
          return id;
        } else {
          new Error();
        }
      } catch (error: any) {
        return rejectWithValue(error.message);
      }
    }
  ),
};
