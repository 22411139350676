//@ts-nocheck

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { scenarioEditorSlice } from "../scenarioEditorSlice";
import { nodeTypes } from "../builders/node.builder";
import { Delete, Save } from "@mui/icons-material";
import TagsInput from "../../common/components/TagsInput/TagsInput";
import React from "react";
import { NodeType } from "../../../types/NodeType.type";

const NodeFormField = (props) => {
  const { node, field, fieldName, handleChange } = props;

  if (node.nodeTypeId === "inputFromList" && fieldName === "options") {
    return (
      <TagsInput
        label={field.label}
        helperText={field?.helperText || ""}
        placeholder={"Začněte psát možnosti"}
        fullWidth={true}
        tags={node.values[fieldName]}
        selectedTags={(tags: any) => {
          const event = {
            target: {
              name: fieldName,
              value: tags,
            },
          };
          handleChange(event);
        }}
      />
    );
  }

  if (field?.type || "" === "select") {
    return (
      <FormControl fullWidth>
        <InputLabel>{field.label}</InputLabel>
        <Select
          name={fieldName}
          value={node.values[fieldName]}
          label={field.label}
          onChange={handleChange}
        >
          {field.options.map((option) => {
            return (
              <MenuItem value={option.value} key={option.value}>
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }

  return (
    <TextField
      label={field.label}
      helperText={field?.helperText || ""}
      fullWidth={true}
      autoFocus={true}
      name={fieldName}
      value={node.values[fieldName]}
      onChange={handleChange}
    />
  );
};

const NodeDialog = () => {
  const dispatch = useDispatch();
  const selectedNodeId: string | null = useSelector(
    (state: RootState) => state.scenarioEditor.selectedNodeId
  );
  const node: NodeType | null = useSelector((state: RootState) =>
    !!selectedNodeId && !!state.scenarioEditor.scenario
      ? state.scenarioEditor.scenario.nodes[selectedNodeId]
      : null
  );

  if (!selectedNodeId || !node) {
    return <></>;
  }

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    if (name === "nodeTypeId") {
      dispatch(
        scenarioEditorSlice.actions.updateNode({
          node: {
            ...node,
            [name]: value,
          },
        })
      );
    } else {
      dispatch(
        scenarioEditorSlice.actions.updateNode({
          node: {
            ...node,
            values: {
              ...node.values,
              [name]: value,
            },
          },
        })
      );
    }
  };

  const handleDelete = () => {
    dispatch(scenarioEditorSlice.actions.deleteNode({ id: node.id }));
    dispatch(scenarioEditorSlice.actions.setSelectedNodeId(null));
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={true}
      onClose={() =>
        dispatch(scenarioEditorSlice.actions.setSelectedNodeId(null))
      }
    >
      <DialogContent sx={{ p: 2, pt: 3 }}>
        <Box>
          <TextField
            select
            size={"small"}
            label="Typ"
            fullWidth={true}
            value={node.nodeTypeId}
            name={"nodeTypeId"}
            onChange={handleChange}
            inputProps={{ sx: { mt: 1, display: "flex" } }}
          >
            {Object.entries(nodeTypes).map(([id, nodeType]) => (
              <MenuItem key={id} value={id}>
                <Box sx={{ display: "flex" }}>
                  {nodeType.icons.map((icon, index) => {
                    return React.createElement(icon, {
                      key: index,
                      sx: { color: nodeType.color[800] },
                      fontSize: "small",
                    });
                  })}
                </Box>
                <Box sx={{ mx: 1 }}>
                  <strong>{nodeType.label}</strong>
                </Box>
                <Box>
                  <small>{nodeType.description}</small>
                </Box>
              </MenuItem>
            ))}
          </TextField>
        </Box>
        {Object.entries(nodeTypes[node.nodeTypeId].fields).map(
          ([fieldName, field]) => {
            return (
              <Box key={fieldName} sx={{ mt: 3 }}>
                <NodeFormField
                  node={node}
                  fieldName={fieldName}
                  field={field}
                  handleChange={handleChange}
                />
              </Box>
            );
          }
        )}
      </DialogContent>
      <DialogActions
        sx={{ px: 2, pb: 2, display: "flex", justifyContent: "start" }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Button
            onClick={() => {
              handleDelete();
            }}
            size={"small"}
            color={"error"}
            variant={"outlined"}
            startIcon={<Delete />}
          >
            Odstranit
          </Button>
        </Box>
        <Box>
          <Button
            onClick={() => {
              dispatch(scenarioEditorSlice.actions.setSelectedNodeId(null));
            }}
            size={"small"}
            color={"primary"}
            variant={"contained"}
            startIcon={<Save />}
          >
            Uložit
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default NodeDialog;
