import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { logOut } from "../../../auth/utils/auth.utils";
import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ListItemText from "@mui/material/ListItemText";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import {
  Box,
  Hidden,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { selectProfile } from "../../../auth/selectors/auth.selector";

const LayoutAppBarProfile = () => {
  const profile = useAppSelector(selectProfile);

  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    logOut(dispatch);
    handleClose();
  };

  const getDisplayName = () => {
    if (!!profile) {
      return profile.username;
    }
    return "";
  };

  if (!profile) {
    return <></>;
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Hidden smDown>
        <Typography variant="subtitle2" sx={{ ml: 1, mr: 2 }}>
          {getDisplayName()}
        </Typography>
      </Hidden>

      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handleLogOut();
          }}
        >
          <ListItemIcon>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Odhlásit se" />
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default LayoutAppBarProfile;
