import { csCZ, DataGrid, GridToolbar } from "@mui/x-data-grid";
import { TableContainer } from "@mui/material";
import React from "react";

const CrudList = (props: any) => {
  const { columns, items, ...rest } = props;

  return (
    <TableContainer sx={{ background: "#ffffff" }}>
      <DataGrid
        columns={columns}
        rows={items}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        disableRowSelectionOnClick={true}
        localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
        autoHeight={true}
        {...rest}
      />
    </TableContainer>
  );
};

export default CrudList;
