// @ts-nocheck

import { Box, MenuItem, Select } from "@mui/material";
import {
  cronDayNames,
  cronModes,
  formatDateTime,
  getCronExpression,
  getCronFormFromCronExpression,
  getCronScheduledDatetimes,
  getDaysOfMonth,
  getDaysOfWeek,
  getHoursOfDay,
} from "./utils";
import React from "react";
import MuiAutocomplete from "../MuiAutocomplete/MuiAutocomplete";

type CronUIProps = {
  onChange: (expression: string) => void;
  defaultExpression: string;
};

const CronUi = (props: CronUIProps) => {
  const { onChange, defaultExpression } = props;

  const [form, setForm] = React.useState(() => {
    return getCronFormFromCronExpression(defaultExpression);
  });

  const isSelected = (name: NameType, value: string): boolean => {
    return form[name].includes(value);
  };

  const handleClick = (name: NameType, value: string): void => {
    setForm({ ...form, [name]: value });
    // if (!isSelected(name, value)) {
    //   setForm({ ...form, [name]: form[name].concat(value) });
    // } else {
    //   setForm({
    //     ...form,
    //     [name]: form[name].filter((id: string) => id !== value),
    //   });
    // }
  };

  const getDays = () => {
    if (["daysInWeek"].includes(form.cronMode)) {
      return getDaysOfWeek();
    } else {
      return getDaysOfMonth();
    }
  };

  React.useEffect(() => {
    if (form.cronDays.length > 0 && form.cronHours.length > 0) {
      onChange(getCronExpression(form.cronMode, form.cronHours, form.cronDays));
    } else {
      onChange("");
    }
  }, [form]);

  return (
    <>
      <Select
        sx={{ mt: 2 }}
        fullWidth
        label={"Frekvence"}
        value={form.cronMode}
        onChange={(event) => {
          setForm({ ...form, cronMode: event.target.value, cronDays: [] });
        }}
      >
        {Object.keys(cronModes).map((cronMode) => {
          return (
            <MenuItem key={cronMode} value={cronMode}>
              {cronModes[cronMode]}
            </MenuItem>
          );
        })}
      </Select>
      <MuiAutocomplete
        label={"Dny"}
        value={form.cronDays}
        options={getDays()}
        getOptionLabel={(day) =>
          ["daysInWeek"].includes(form.cronMode) ? cronDayNames[day] : day
        }
        onChange={(newValue) => {
          handleClick("cronDays", newValue);
        }}
      />
      <MuiAutocomplete
        label={"Hodiny"}
        value={form.cronHours}
        options={getHoursOfDay()}
        getOptionLabel={(hour) => (hour < 10 ? "0" + hour : hour) + ":00"}
        onChange={(newValue) => {
          handleClick("cronHours", newValue);
        }}
      />

      <Box>
        {form.cronDays.length > 0 && form.cronHours.length > 0 && (
          <Box sx={{ fontFamily: "monospace", fontSize: 13 }}>
            {getCronScheduledDatetimes(
              getCronExpression(form.cronMode, form.cronHours, form.cronDays),
              5
            ).map((datetime) => {
              return <Box>{formatDateTime(datetime.toISOString())}</Box>;
            })}
            <Box>...</Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default React.memo(CronUi);
