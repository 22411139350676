import React from "react";
import { Delete, Edit } from "@mui/icons-material";
import { selectAll } from "../selectors/selectors";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ENTITY_TYPE } from "../config";
import { remove, update } from "../actions/actions";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid";
import CrudList from "../../common/components/CrudList/CrudList";
import { selectById as selectUnitById } from "../.././unit/selectors/selectors";
import { selectById as selectScenarioById } from "../.././scenario/selectors/selectors";
import { Box, Chip, Tooltip } from "@mui/material";
import {
  formatDateTime,
  getCronDescriptionFromCronExpression,
  getCronScheduledDatetimes,
} from "../../common/components/CronUi/utils";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { getHDMLabel } from "../utils/utils";

const Unit = (props: { id: string }) => {
  const { id } = props;

  const entity = useAppSelector((state) => selectUnitById(state, { id }));

  if (!entity) {
    return <></>;
  }

  return <Chip sx={{ mr: 0.5 }} size={"small"} label={entity.storeNumber} />;
};

const Scenario = (props: { id: string }) => {
  const { id } = props;

  const entity = useAppSelector((state) => selectScenarioById(state, { id }));

  if (!entity) {
    return <></>;
  }

  return (
    <Chip
      sx={{ mr: 0.5 }}
      size={"small"}
      label={entity.code + ": " + entity.title}
    />
  );
};

const List = () => {
  const dispatch = useAppDispatch();
  const items = useAppSelector(selectAll);

  const columns: GridColDef[] = [
    {
      field: "enabled",
      headerName: "Povolený",
      flex: 1,
      renderCell: (params) => {
        if (!!params.value) {
          return <CheckBoxIcon />;
        }
        return <CheckBoxOutlineBlankIcon />;
      },
    },
    {
      field: "unitIds",
      headerName: "Střediska",
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            {params.value.map((id: string) => (
              <Unit key={id} id={id} />
            ))}
          </>
        );
      },
    },
    {
      field: "scenarioIds",
      headerName: "Scénáře",
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            {params.value.map((id: string) => (
              <Scenario key={id} id={id} />
            ))}
          </>
        );
      },
    },
    {
      field: "cronExpression",
      headerName: "Vyplnit kdy",
      flex: 1,
      renderCell: (params) => {
        const description = getCronDescriptionFromCronExpression(params.value);
        const scheduled = getCronScheduledDatetimes(params.value, 2);
        if (!scheduled || scheduled.length !== 1 || !params.row.withCron) {
          return <></>;
        }
        return (
          <Box>
            <Box>
              <strong>{description.cronMode}</strong>
            </Box>
            <Box>{formatDateTime(scheduled[0].toISOString())}</Box>
          </Box>
        );
      },
    },
    {
      field: "ttl",
      headerName: "Čas na vyplnění",
      flex: 1,
      renderCell: (params) => {
        if (!params.row.withCron) {
          return <></>;
        }
        return (
          <>
            {params.row.ttl}&nbsp;
            {getHDMLabel(params.row.ttlUnit as "H" | "D" | "M", params.row.ttl)}
          </>
        );
      },
    },
    {
      field: "createdBy",
      headerName: "Vytvořil",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Akce",
      flex: 1,
      type: "actions",
      sortable: false,
      filterable: false,
      hideable: false,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={
            <Tooltip title="Upravit">
              <Edit />
            </Tooltip>
          }
          onClick={() => {
            handleUpdate(params.row);
          }}
          label={"Upravit"}
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title="Odstranit">
              <Delete />
            </Tooltip>
          }
          onClick={() => {
            handleDelete(params.row);
          }}
          label={"Odstranit"}
        />,
      ],
    },
  ];

  const handleUpdate = (entity: ENTITY_TYPE) => {
    dispatch(update(entity));
  };

  const handleDelete = (entity: ENTITY_TYPE) => {
    dispatch(remove(entity));
  };

  return <CrudList columns={columns} items={items} />;
};

export default List;
