import { ScenarioExecutionState } from "./reducer.util";
import {
  NODES_INPUT,
  SCENARIO_EXECUTION_STATUS,
  SCENARIO_MODE,
} from "./constants.util";
import { edgeConditionOperationTypes } from "../../../../../enums/edgeConditionOperationTypes";
import { EdgeType } from "../../../../../types/EdgeType.type";

export const getScenarioExecutionStatus = (
  scenarioExecutionState: ScenarioExecutionState
) => {
  if (scenarioExecutionState.progress.stepIds.length === 0) {
    return SCENARIO_EXECUTION_STATUS.NOT_COMPLETED;
  }

  if (
    scenarioExecutionState.progress.mode === SCENARIO_MODE.SEQ &&
    scenarioExecutionState.progress.stepIds.filter((stepId) => {
      return (
        scenarioExecutionState.progress.stepsByIds[stepId].nodeTypeId === "end"
      );
    }).length === 1
  ) {
    return SCENARIO_EXECUTION_STATUS.COMPLETED;
  }

  if (
    scenarioExecutionState.progress.mode === SCENARIO_MODE.ALL &&
    scenarioExecutionState.progress.stepIds
      .filter((stepId) => {
        return NODES_INPUT.includes(
          scenarioExecutionState.progress.stepsByIds[stepId].nodeTypeId
        );
      })
      .every((stepId) => {
        return !!scenarioExecutionState.progress.stepsByIds[stepId].submittedAt;
      })
  ) {
    return SCENARIO_EXECUTION_STATUS.COMPLETED;
  }

  return SCENARIO_EXECUTION_STATUS.IN_PROGRESS;
};

export const findDestinationIdForNodeId = (
  edges: Record<string, EdgeType>,
  nodeId: string,
  executionValue: any
) => {
  const edge = Object.values(edges)
    .sort((a: EdgeType, b: EdgeType) => {
      return (
        edgeConditionOperationTypes[
          a.values.condition
            .operation as keyof typeof edgeConditionOperationTypes
        ].fnOrder -
        edgeConditionOperationTypes[
          b.values.condition
            .operation as keyof typeof edgeConditionOperationTypes
        ].fnOrder
      );
    })
    .find((edge: any) => {
      return (
        edge.sourceId === nodeId &&
        edge.sourceId !== edge.destinationId &&
        edgeConditionOperationTypes[
          edge.values.condition
            .operation as keyof typeof edgeConditionOperationTypes
        ].fn(executionValue, edge.values.condition.value)
      );
    });

  if (!!edge) {
    return edge.destinationId;
  }

  return null;
};
