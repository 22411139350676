import { selectAll } from "./selectors/selectors";
import { thunks } from "./thunks/thunks";
import { ENTITY_NAME } from "./config";
import { slice } from "./slice/slice";

export const unit = {
  name: ENTITY_NAME,
  slice: slice,
  actions: {
    ...slice.actions,
    getAll: thunks.getAll,
    post: thunks.post,
    put: thunks.put,
    delete: thunks.delete,
  },
  selectors: {
    selectAll: selectAll,
  },
};
