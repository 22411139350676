import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../services/axios";
import { BASE_URL, ENTITY_NAME } from "../config";
import { buildHeaders } from "../../../api/api";

export const thunks = {
  getAll: createAsyncThunk(
    ENTITY_NAME + "/getAll",
    async (arg: any, { rejectWithValue }) => {
      try {
        const response = await axios.get(BASE_URL, {
          headers: buildHeaders({
            "content-type": "application/ld+json",
          }),
        });
        if (response.status === 200) {
          return response.data;
        } else {
          new Error();
        }
      } catch (error: any) {
        return rejectWithValue(error.message);
      }
    }
  ),
};
