// @ts-nocheck

import parser from "cron-parser";

export const cronModes = {
  daysInWeek: "Vybrané dny každý týden",
  daysInMonth: "Vybrané dny každého měsíce",
};

export const cronDayNames = ["ne", "po", "út", "st", "čt", "pá", "so", "ne"];

export const getDaysOfWeek = () => {
  return [1, 2, 3, 4, 5, 6, 0];
};

export const getDaysOfMonth = () => {
  return [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];
};

export const getHoursOfDay = () => {
  return [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23,
  ];
};

export const formatDate = (dtString) => {
  const dt = new Date(dtString);
  const date = [dt.getDate(), dt.getMonth() + 1, dt.getFullYear()]
    .map((t) => {
      return t < 10 ? "0" + t : t;
    })
    .join(".");

  return cronDayNames[dt.getDay()] + " " + date;
};

export const formatDateTime = (dtString) => {
  const dt = new Date(dtString);
  return (
    formatDate(dtString) +
    " " +
    [dt.getHours(), dt.getMinutes()]
      .map((t) => {
        return t < 10 ? "0" + t : t;
      })
      .join(":")
  );
};

export const getCronExpression = (mode, hours, days) => {
  if (hours.length === 0 || days.length === 0) {
    return "0 * * * *";
  }

  if (mode === "daysInWeek") {
    return `0 ${hours.join(",")} * * ${days.join(",")}`;
  }

  return `0 ${hours.join(",")} ${days.join(",")} * *`;
};

export const getCronScheduledDatetimes = (cronExpression, repeat) => {
  const options = {
    currentDate: new Date(),
  };

  const interval = parser.parseExpression(`0 ${cronExpression}`, options);

  const result = [];

  for (let i = 1; i < repeat; i++) {
    result.push(interval.next());
  }

  return result;
};

export const getCronFormFromCronExpression = (expression: string) => {
  const d = {
    cronMode: "daysInWeek",
    cronDays: [],
    cronHours: [],
  };

  const parts = expression.split(" ");

  if (parts.length !== 5) {
    return d;
  }

  if (parts[2] !== "*") {
    return {
      cronMode: "daysInMonth",
      cronDays: parts[2].split(",").map((t) => t * 1),
      cronHours: parts[1].split(",").map((t) => t * 1),
    };
  }

  if (parts[4] !== "*") {
    return {
      cronMode: "daysInWeek",
      cronDays: parts[4].split(",").map((t) => t * 1),
      cronHours: parts[1].split(",").map((t) => t * 1),
    };
  }

  return d;
};

export const getCronDescriptionFromCronExpression = (expression: string) => {
  const cronForm = getCronFormFromCronExpression(expression);
  return {
    cronMode: cronModes[cronForm.cronMode],
    cronDays:
      cronForm.cronMode === "daysInMonth"
        ? cronForm.cronDays.map((d) => d).join(", ")
        : cronForm.cronDays.map((d) => cronDayNames[d]).join(", "),
    cronHours: cronForm.cronHours.join(", "),
  };
};
