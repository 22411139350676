import { AppDispatch } from "../../../app/store";
import { scenarioPrescription } from "../index";
import { buildEntity } from "../builder/builder";
import { ENTITY_TYPE } from "../config";

export const load = () => (dispatch: AppDispatch) => {
  dispatch(scenarioPrescription.actions.getAll({}));
};

export const create = () => (dispatch: AppDispatch) => {
  const entity = buildEntity();
  dispatch(scenarioPrescription.actions.setSelected(entity));
  dispatch(scenarioPrescription.actions.setSelectedMode("create"));
};

export const update = (entity: ENTITY_TYPE) => (dispatch: AppDispatch) => {
  dispatch(scenarioPrescription.actions.setSelected(entity));
  dispatch(scenarioPrescription.actions.setSelectedMode("update"));
};

export const remove = (entity: ENTITY_TYPE) => (dispatch: AppDispatch) => {
  dispatch(scenarioPrescription.actions.setSelected(entity));
  dispatch(scenarioPrescription.actions.setSelectedMode("delete"));
};

export const setSelected =
  (newValue: ENTITY_TYPE) => (dispatch: AppDispatch) => {
    dispatch(scenarioPrescription.actions.setSelected(newValue));
  };

export const resetSelected = () => (dispatch: AppDispatch) => {
  dispatch(scenarioPrescription.actions.setSelected(null));
};

export const resetSelectedMode = () => (dispatch: AppDispatch) => {
  dispatch(scenarioPrescription.actions.setSelectedMode(null));
};

export const confirmCreate = (data: any) => (dispatch: AppDispatch) => {
  return dispatch(scenarioPrescription.actions.post({ data }));
};

export const confirmUpdate = (data: any) => (dispatch: AppDispatch) => {
  return dispatch(scenarioPrescription.actions.put({ data }));
};

export const confirmDelete = (data: any) => (dispatch: AppDispatch) => {
  return dispatch(scenarioPrescription.actions.delete({ data }));
};
