import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

const CloseButton = (props) => {
  return (
    <IconButton
      aria-label="close"
      onClick={props.onClick}
      sx={{
        position: "absolute",
        right: 12,
        top: 12,
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};
export default CloseButton;
