import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { EDGE_WIDTH, NODE_HEIGHT, NODE_WIDTH } from "../constants/constants";
import { scenarioEditorSlice } from "../scenarioEditorSlice";
import { useDispatch } from "react-redux";
import { getNodeCenterCoords } from "../utils/utils";
import { edgeConditionOperationTypes } from "../builders/edge.builder";
import { EdgeType } from "../../../types/EdgeType.type";
import { EdgeCoordinateType } from "../../../types/EdgeCoordinateType.type";

const getNodeQuadrant = (
  edgeCoordinate: EdgeCoordinateType
): "I" | "II" | "III" | "IV" => {
  const angleAlphaRad = Math.atan(NODE_HEIGHT / NODE_WIDTH);
  //const angleBetaRad = Math.atan(b / a);

  if (
    edgeCoordinate.angleRad > 0 - angleAlphaRad &&
    edgeCoordinate.angleRad <= angleAlphaRad
  ) {
    return "I";
  }

  if (
    edgeCoordinate.angleRad > angleAlphaRad &&
    edgeCoordinate.angleRad <= Math.PI - angleAlphaRad
  ) {
    return "II";
  }

  if (
    edgeCoordinate.angleRad > -1 * Math.PI + angleAlphaRad &&
    edgeCoordinate.angleRad <= 0 - angleAlphaRad
  ) {
    return "IV";
  }

  return "III";
};

const getNodeLineOverflowDistance = (
  edgeCoordinate: EdgeCoordinateType
): number => {
  const quadrant = getNodeQuadrant(edgeCoordinate);

  if (["I", "III"].includes(quadrant)) {
    return Math.abs(
      NODE_WIDTH / 2 / Math.cos(Math.abs(edgeCoordinate.angleRad))
    );
  }

  if (["II", "IV"].includes(quadrant)) {
    return Math.abs(
      NODE_HEIGHT / 2 / Math.sin(Math.abs(edgeCoordinate.angleRad))
    );
  }

  return 0;
};

type EdgeLabelProps = {
  edge: EdgeType;
  coordinates: EdgeCoordinateType;
  handleEdgeClick: any;
};

const EdgeLabel = (props: EdgeLabelProps) => {
  const { edge, coordinates, handleEdgeClick } = props;

  const [coords, setCoords] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    const n1 = getNodeCenterCoords(edge.sourceId);
    const n2 = getNodeCenterCoords(edge.destinationId);

    const el = window.document.getElementById("edgeLabel_" + edge.id);

    if (el) {
      setCoords({
        x: (n1.x + n2.x) / (100 / 50) - el.offsetWidth / 2,
        y: (n1.y + n2.y) / (100 / 50) - el.offsetHeight / 2,
      });
    }
  }, [edge, coordinates]);

  if (edge.values.condition.value === "") {
    return <></>;
  }

  return (
    <Box
      onDoubleClick={handleEdgeClick}
      id={"edgeLabel_" + edge.id}
      sx={{
        cursor: "pointer",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "rgba(0, 0, 0, 0.12)",
        background: "rgba(255, 255, 255, 0.95)",
        zIndex: 2,
        position: "absolute",
        left: coords.x,
        top: coords.y,
        p: 0.5,
        borderRadius: 2,
        fontSize: 12,
        fontWeight: 600,
      }}
    >
      {edgeConditionOperationTypes[edge.values.condition.operation].label}{" "}
      {edge.values.condition.value}
    </Box>
  );
};

type EdgeProps = {
  edge: EdgeType;
  coordinates: EdgeCoordinateType;
};

const Edge = (props: EdgeProps) => {
  const { edge, coordinates } = props;

  const dispatch = useDispatch();

  const overflowDistance = getNodeLineOverflowDistance(coordinates);

  const handleEdgeClick = () => {
    dispatch(scenarioEditorSlice.actions.setSelectedEdgeId(edge.id));
  };

  return (
    <>
      <EdgeLabel
        edge={edge}
        coordinates={coordinates}
        handleEdgeClick={handleEdgeClick}
      />
      <Box
        onDoubleClick={handleEdgeClick}
        sx={{
          zIndex: 1,
          position: "absolute",
          left: coordinates.x,
          top: coordinates.y,
          width: coordinates.distance,
          height: 20,
          transform: "rotate(" + coordinates.angleRad + "rad)",
          transformOrigin: "left",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: (overflowDistance / coordinates.distance) * 100 + "%",
          }}
        />
        <Box
          sx={{
            flexGrow: 1,
            height: EDGE_WIDTH + "px",
            background: "#000",
          }}
        />
        <Box
          sx={{
            marginLeft: "-2px",
            color: "#000",
          }}
        >
          &#9654;
        </Box>
        <Box
          sx={{
            width: (overflowDistance / coordinates.distance) * 100 + "%",
          }}
        />
      </Box>
    </>
  );
};

export default Edge;
