import { v4 as uuidv4 } from "uuid";
import { ENTITY_TYPE } from "../config";

export const buildEntity = (): ENTITY_TYPE => {
  return {
    id: uuidv4(),
    brandCode: "",
    email: "",
    emailFieldManager: "",
    storeNumber: "",
  };
};
