import { useDraggable, useDroppable } from "@dnd-kit/core";
import { Box, IconButton } from "@mui/material";
import { ArrowRightAlt } from "@mui/icons-material";
import React, { useState } from "react";
import {
  NODE_BORDER_WIDTH,
  NODE_HEIGHT,
  NODE_WIDTH,
} from "../constants/constants";
import { scenarioEditorSlice } from "../scenarioEditorSlice";
import { useDispatch } from "react-redux";
import { nodeTypes } from "../builders/node.builder";
import { NodeCoordinateType } from "../../../types/NodeCoordinateType.type";
import { NodeType } from "../../../types/NodeType.type";

const IconButtonEdge = (props: any) => {
  const { node } = props;

  const { attributes, listeners, setNodeRef } = useDraggable({
    id: node.id,
  });

  return (
    <IconButton ref={setNodeRef} {...attributes} {...listeners}>
      <ArrowRightAlt />
    </IconButton>
  );
};

type NodeProps = {
  coordinates: NodeCoordinateType;
  node: NodeType;
};

const Node = (props: NodeProps) => {
  const { coordinates, node } = props;

  const id = ["node", node.id].join("_");

  const nodeType = nodeTypes[node.nodeTypeId];

  const dispatch = useDispatch();

  const draggable = useDraggable({
    id: id,
    data: {
      type: "node",
    },
  });

  const droppable = useDroppable({
    id: id,
    data: {
      accepts: ["nodeInterface"],
    },
  });

  const [menuVisible, setMenuVisible] = useState(false);

  const handleNodeClick = () => {
    setMenuVisible(false);
    dispatch(scenarioEditorSlice.actions.setSelectedNodeId(node.id));
  };

  const getLabel = () => {
    if (nodeType.hasOwnProperty("getLabel")) {
      // @ts-ignore
      return nodeType.getLabel(node);
    }
    return node.values.displayText;
  };

  // @ts-ignore
  return (
    <>
      <Box
        id={id}
        sx={{
          position: "absolute",
          zIndex: coordinates.z,
          top: coordinates.y,
          left: coordinates.x,
          transform: draggable.transform
            ? `translate3d(${draggable.transform.x}px, ${draggable.transform.y}px, 0)`
            : undefined,
        }}
        onMouseEnter={() => {
          setMenuVisible(true);
        }}
        onMouseLeave={() => {
          setMenuVisible(false);
        }}
        ref={draggable.setNodeRef}
        {...draggable.attributes}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            onDoubleClick={handleNodeClick}
            ref={droppable.setNodeRef}
            sx={{
              p: 0.5,
              width: NODE_WIDTH,
              height: NODE_HEIGHT,
              background: nodeType.color[100],
              boxShadow: draggable.isDragging ? 8 : 0,
              transition: "box-shadow 500ms",
              borderStyle: droppable.isOver ? "dashed" : "solid",
              borderWidth: NODE_BORDER_WIDTH,
              borderColor: (theme) => "rgba(0, 0, 0, 0.12)",
              borderRadius: 1,
              boxSizing: "border-box",
              display: "flex",
              // alignItems: "center",
              //textAlign: "center",
              fontSize: 12,
              cursor: draggable.isDragging ? "grabbing" : "grab",
            }}
            {...draggable.listeners}
          >
            <Box>
              {nodeType.icons.map((icon, index) => {
                return (
                  <Box key={index}>
                    {React.createElement(icon, {
                      sx: { color: nodeType.color[800], mr: 0.5 },
                      fontSize: "small",
                    })}
                  </Box>
                );
              })}
            </Box>
            <Box sx={{ flexGrow: 1, mt: 0.25, overflow: "hidden" }}>
              <Box sx={{ fontWeight: "bold" }}>{node.values.customId}</Box>
              <Box>{getLabel()}</Box>
            </Box>
          </Box>
          <Box
            sx={{
              px: 0.5,
              transition: "all 500ms",
              opacity: menuVisible ? 1 : 0,
            }}
          >
            <Box>
              <IconButtonEdge node={node} />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Node;
