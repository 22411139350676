import { Box, Button } from "@mui/material";
import { buildNode } from "../builders/node.builder";
import { AddCircle } from "@mui/icons-material";
import { scenarioEditorSlice } from "../scenarioEditorSlice";
import { useDispatch } from "react-redux";
import { NodeType } from "../../../types/NodeType.type";
import { NodeCoordinateType } from "../../../types/NodeCoordinateType.type";

const Toolbar = () => {
  const dispatch = useDispatch();

  const addNode = () => {
    const node: NodeType = buildNode("text");
    const nodeCoordinate: NodeCoordinateType = {
      x: 8 * 2,
      y: 8 * 8,
      z: 0,
    };
    dispatch(scenarioEditorSlice.actions.addNode({ node, nodeCoordinate }));
  };

  return (
    <Box sx={{ p: 2 }}>
      <Button
        size={"small"}
        variant={"outlined"}
        color={"inherit"}
        startIcon={<AddCircle />}
        onClick={() => {
          addNode();
        }}
      >
        Přidat krok
      </Button>
      {/*{Object.entries(nodeTypes).map(([id, type]) => {*/}
      {/*  return <MenuItemNode key={id} id={id} nodeType={type} />;*/}
      {/*})}*/}
    </Box>
  );
};

export default Toolbar;
